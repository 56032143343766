@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

button.mobile-favourite-button:after {
    background: none;
}

/* Blog */
#blog {
    padding-top: 2rem;
}

#blog > .flex, .blogPosts {
    justify-content: space-between;
}

.smLink {
    margin-left: 0px !important;
    max-width: 5px;
}

.copyLink {
    margin-right: 15px;
}

.blogItem {
    flex-basis: 23%;
}

.blogItem a {
    text-decoration: none;
    display: block;
}

.blogItem .blogCover {
    margin-bottom: 1rem;
}

.blogItem .blogCover img {
    max-width: 100%;
    height: auto;
    display: block;
}

.blogItem .blogTitle, #content .blogItem .blogTitle {
    color: #111;
    margin-bottom: .5rem;
}

.blogItem .blogTag, #content .blogItem .blogTag, #content .singleMeta .blogTag {
    color: #FF2800;
    font-size: .7rem;
    font-weight: 700;
    text-transform: uppercase;
}

.blogPostsHeading {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

#content .blogPostsHeading h3 {
    margin-bottom: 0;
}

.blogPosts .blogItem {
    font-size: .9rem;
    margin-bottom: 2rem;
}

.blogTags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.blogTags > a {
    margin-right: .5rem;
}

.blogFeatured .blogCover {
    flex-basis: 30%;
}

.blogFeatured .blogShort {
    font-size: 0.9rem;
    flex-basis: calc(70% - 40px);
    margin-left: auto;
}

#content .blogFeatured h4 {
    margin-bottom: 1rem;
}

.blogPostsWrapper .blogFeatured {
    margin-bottom: 1rem;
}

.metaLabels .metaLabel {
    padding: 1rem;
    background: #FF2800;
    color: #fff;
    margin-right: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    font-size: .75rem;
}

.singleMeta {
    align-items: center;
    margin-bottom: 1rem;
}

.metaDate span, #content .metaDate a {
    color: #999;
    font-size: .875rem;
}

.singleMeta .blogTags > a {
    margin-right: 1rem;
}

.singleIntro {
    margin-bottom: 1rem;
}

.singleIntro .singleCover {
    flex-basis: 30%;
}

.singleIntro .singleIntroText {
    flex-basis: calc(70% - 40px);
    margin-left: auto;
}

.coverLabel {
    margin-bottom: 1rem;
    font-size: .875rem;
    color: #999;
}

.singleIntroText {
    font-weight: bold;
    font-size: 1.125rem;
}

#content .singleContent h1, #content .singleContent h2, #content .singleContent h3, #content .singleContent h4, #content .singleContent h5, #content .singleContent h6 {
    text-transform: none;
    margin-bottom: 1rem
}

.singleBottom {
    padding: 1rem;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 1rem;
    justify-content: space-between;
}

.shareThis a {
    margin-left: 1rem;
}

.singleComments {
    padding: 1rem;
    background: #f9f9f9;
    margin-bottom: 2rem;
}

#content .singleComments > * {
    margin-bottom: 0;
}

#content .blogShort a {
    color: #111;
}


/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
.owl-carousel, .owl-carousel .owl-item {
    -webkit-tap-highlight-color: transparent;
    position: relative;
    padding-left: 3px;
    padding-right: 3px;
}

.owl-carousel {
    display: none;
    width: 100%;
    z-index: 1
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    -moz-backface-visibility: hidden
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-item {
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%
}

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
    display: none
}

.no-js .owl-carousel, .owl-carousel.owl-loaded {
    display: block
}

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block
}

.owl-carousel.owl-hidden {
    opacity: 0
}

.owl-carousel.owl-refresh .owl-item {
    visibility: hidden
}

.owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab
}

.owl-carousel.owl-rtl {
    direction: rtl
}

.owl-carousel.owl-rtl .owl-item {
    float: right
}

.owl-carousel .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.owl-carousel .owl-animated-in {
    z-index: 0
}

.owl-carousel .owl-animated-out {
    z-index: 1
}

.owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

.owl-height {
    -webkit-transition: height .5s ease-in-out;
    -o-transition: height .5s ease-in-out;
    transition: height .5s ease-in-out
}

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    -webkit-transition: opacity .4s ease;
    -o-transition: opacity .4s ease;
    transition: opacity .4s ease
}

.owl-carousel .owl-item img.owl-lazy {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000
}

.owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -webkit-transition: -webkit-transform .1s ease;
    transition: -webkit-transform .1s ease;
    -o-transition: transform .1s ease;
    transition: transform .1s ease;
    transition: transform .1s ease, -webkit-transform .1s ease
}

.owl-carousel .owl-video-play-icon:hover {
    -ms-transform: scale(1.3, 1.3);
    -webkit-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3)
}

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
    display: none
}

.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transition: opacity .4s ease;
    -o-transition: opacity .4s ease;
    transition: opacity .4s ease
}

.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%
}

.owl-nav button::after, .owl-dots button::after {
    display: none;
}

.owl-nav button, .owl-dots button {
    border: none !important;
}

#hot-offers-slider .owl-nav button.owl-prev
    /* lightbox */
.lb-loader, .lightbox {
    text-align: center;
    line-height: 0;
    position: absolute;
    left: 0
}

body.lb-disable-scrolling {
    overflow: hidden
}

.lightboxOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #000;
    filter: alpha(Opacity=80);
    opacity: .8;
    display: none
}

.lightbox {
    width: 100%;
    z-index: 10000;
    font-weight: 400;
    outline: 0
}

.lightbox .lb-image {
    display: block;
    height: auto;
    max-width: inherit;
    max-height: none;
    border-radius: 3px;
    border: 4px solid #fff
}

.lightbox a img {
    border: none
}

.lb-outerContainer {
    position: relative;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    border-radius: 4px;
    background-color: #fff
}

.lb-outerContainer:after {
    content: "";
    display: table;
    clear: both
}

.lb-loader {
    top: 43%;
    height: 25%;
    width: 100%
}

.lb-cancel {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    background: url(assets/images/lightbox/loading.gif) no-repeat
}

.lb-nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10
}

.lb-container > .nav {
    left: 0
}

.lb-nav a {
    outline: 0;
    background-image: url(data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==)
}

.lb-next, .lb-prev {
    height: 100%;
    cursor: pointer;
    display: block
}

.lb-nav a.lb-prev {
    width: 34%;
    left: 0;
    float: left;
    background: url(assets/images/lightbox/prev.png) left 48% no-repeat;
    filter: alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity .6s;
    -o-transition: opacity .6s;
    transition: opacity .6s
}

.lb-nav a.lb-prev:hover {
    filter: alpha(Opacity=100);
    opacity: 1
}

.lb-nav a.lb-next {
    width: 64%;
    right: 0;
    float: right;
    background: url(assets/images/lightbox/next.png) right 48% no-repeat;
    filter: alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity .6s;
    -o-transition: opacity .6s;
    transition: opacity .6s
}

.lb-nav a.lb-next:hover {
    filter: alpha(Opacity=100);
    opacity: 1
}

.lb-dataContainer {
    margin: 0 auto;
    padding-top: 5px;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

.lb-dataContainer:after {
    content: "";
    display: table;
    clear: both
}

.lb-data {
    padding: 0 4px;
    color: #ccc
}

.lb-data .lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em
}

.lb-data .lb-caption {
    font-size: 0.8125rem;
    font-weight: 700;
    line-height: 1em
}

.lb-data .lb-caption a {
    color: #4ae
}

.lb-data .lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 0.75rem;
    color: #999
}

.lb-data .lb-close {
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    background: url(assets/images/lightbox/close.png) top right no-repeat;
    text-align: right;
    outline: 0;
    filter: alpha(Opacity=70);
    opacity: .7;
    -webkit-transition: opacity .2s;
    -o-transition: opacity .2s;
    transition: opacity .2s
}

.lb-data .lb-close:hover {
    cursor: pointer;
    filter: alpha(Opacity=100);
    opacity: 1
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, audio, canvas, details, figcaption, figure, footer, header, hgroup, mark, menu, meter, nav, output, progress, section, summary, time, video {
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    margin: 0;
    padding: 0;
}

body {
    line-height: 1;
}

article, aside, dialog, figure, footer, header, hgroup, nav, section, blockquote {
    display: block;
}

nav ul {
    list-style: none;
}

ol {
    list-style: decimal;
}

ul {
    list-style: disc;
}

ul ul {
    list-style: circle;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: none;
}

ins {
    text-decoration: underline;
}

del {
    text-decoration: line-through;
}

mark {
    background: none;
}

abbr[title], dfn[title] {
    border-bottom: 1px dotted #000;
    cursor: help;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #e7e7e7;
    margin: 2rem 0;
    padding: 0;
}

input, select, a img {
    vertical-align: middle;
}

ul {
    list-style: none;
}


body, input, textarea, select, button {
    font-family: "Lato", sans-serif;
}

html {
    font-size: 16px;
}

body {
    font-size: 1rem;
    line-height: 1.5;
    color: #111;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container {
    margin: 0 auto;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

i {
    margin-right: 5px;
}

.hiddenBody {
    overflow-y: scroll;
    position: relative;
    height: 100%;
}

html.hiddenBody {
    overflow-y: hidden;
}

.hidden {
    display: none;
}

.visible {
    display: block;
}

/* Basic colors */
.btn, #content .btn {
    background: #B8970F;
    color: #fff;
    cursor: pointer;
}

.btn:hover, #content .btn:hover {
    background: #333;
    cursor: pointer;
}

#content a, footer a:hover {
    color: #B8970F;
    text-decoration: none;
}

ul.menu a {
    color: #111;
}

.red {
    color: #FF2800;
}

.blue {
    color: #176FB7;
}

.gold {
    color: #B8970F;
}

.white {
    color: 	#ffffff;
}

.btn.red {
    background: #FF2800;
    color: #fff;
}

a.btn:hover {
    color: #ffffff;
}

a, .app-toaster a, span.as-link {
    color: #176FB7;
    cursor: pointer
}

.app-toaster a.black{
    color: #000000 !important;
}

a:hover, .app-toaster a:hover, span.as-link:hover {
    text-decoration: underline;
}


/* Buttons */
.new-used-buttons {
    margin-top: -1.3rem;
    margin-bottom: -1rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    padding-bottom: 1.75rem;
}

.new-used-button {
    height: 35px;
    line-height: 35px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 2px solid #e7e7e7;
    border-left: 2px solid #e7e7e7;
    background-color: white;
    color: black;
    width: 90px;
    text-align: center;
    margin-right: 1.25rem;
}

.new-used-button:hover {
    cursor: pointer;
}

.selected {
    color: white;
    border: none;
    font-weight: 900;
    background-color: #176FB7;
}

.btn {
    display: block;
    margin: 0 auto;
    width: 240px;
    height: 50px;
    text-align: center;
    text-decoration: none !important;
    line-height: 50px;
    border: none;
    border-radius: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 900;
}

.btn:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: url(assets/images/icons/arrow_circle_right_white.svg) center center no-repeat;
    background-size: auto 100%;
    margin-left: 10px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.btn, .offer-btn, button, input[type="submit"], .can-toggle label .can-toggle__switch:after, .tab-link, .can-toggle label .can-toggle__switch:before, .kroki a, .kroki span, .like-btn {
    border-bottom: 3px solid rgba(0, 0, 0, .15) !important;
}

.bp3-input:disabled, .bp3-input.bp3-disabled {
    background: rgba(206, 217, 224, 1);
}

/* Header */
header {
    background: #fff;
    font-size: 0.875rem;
}

header .container {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-line-pack: center;
    align-content: center;
    height: 100px;
}

.single-heading-1 img {
    width: 35px;
    margin-right: 10px;
}

#logo img {
    width: 160px;
}

.hour-line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.day {
    -ms-flex-preferred-size: 100px;
    flex-basis: 100px;
    font-weight: 900;
}

.top-icon img {
    max-height: 24px;
    margin-right: 5px;
}

#top-phone img {
    max-height: 24px;
    margin-right: 10px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#footer-phone:hover {
    text-decoration: underline;
}

.footer-column img {
    max-height: 24px;
    margin-right: 10px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#top-mail {
    margin: 0 10px 0 10px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#favourite-button {
    margin: 0 0 0 5px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    padding-top: 16px;
}

.favourite-vehicle-counter {
    font-weight: bold;
    height: 20px;
    width: 20px;
    background-color: #222222;
    color: #ffffff;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    padding-bottom: 10px;
}

.bp3-icon.bp3-intent-primary{
    padding-bottom: 10px !important;
}

.mobile-favourite-vehicle-counter {
    padding-left: 10px;
    padding-bottom: 5px;
    font-weight: bold;
    margin-top: 30px;
    margin-left: -5px;
    margin-right: -60px;
    height: 25px;
    width: 25px;
    background-color: #222222;
    border-radius: 50%;
    display: inline-block;
}

.mobile-favourite-vehicle-counter-amount-less-than-10 {
    font-weight: bold;
    margin-top: -12px;
    margin-right: 15px;
    margin-left: -2px;
}

.mobile-favourite-vehicle-counter-amount-more-than-10 {
    font-weight: bold;
    margin-top: -12px;
    margin-right: 15px;
    margin-left: -6px;
}

#favourite-button-head-section{
    display: inline-block;
    margin-left: 0px;
    margin-right: -9px;
    padding-bottom: 3px;
    padding-top: 5px;
}

#favourite-button-head-section img{
    max-height: 27px;
    padding-bottom: 10px;
}

#favourite-button-head-section img:hover{
    cursor: pointer;
}

#favourite-button-offer-page{
    display: inline-block;
    margin-top: 6px;
    color:#106ba3;
}

#favourite-button-offer-page img{
    height: 35px;
    width: 20px;
    padding-bottom: 10px;
}

.list-view  #favourite-button-offer-page{
    display: inline-block;
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding-bottom: 10px;
}

.list-view  #favourite-button-offer-page img{
    height: 30px;
    padding-bottom: 10px;
}

#favourite-button img {
    height: 20px;
}

#top-mail img {
    height: 34px;
}

header a {
    text-decoration: none;
}

.top-icon a {
    color: #FF2800;
    font-weight: 900;
}

#menuToggle {
    color: #111;
    font-weight: 900;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    font-size: 1rem;
}

#menuToggle img {
    margin-right: 10px;
    max-height: 16px;
    width: auto;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#top-contact .hours {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
}

#top-contact .day {
    -ms-flex-preferred-size: 35%;
    flex-basis: 35%;
}

#top-phone {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
}

#phone-not-found {
    display: flex;
    align-content: center;
    font-size: 1rem;
    color: #176FB7;
    font-weight: 900;
}

#phone-not-found-form {
    display: inline-block;
    align-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

#phone-not-found-form img {
    max-height: 18px;
    margin-left: 2px;
}

#top-phone a {
    font-size: 1.5rem;
    color: #176FB7;
    font-weight: 900;
}

#top-phone span {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

header > .flex > * {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

header .hour-line {
    width: 180px;
}

#switch {
    width: 160px;
}

/* Main menu */
#main-menu-wrapper {
    position: relative;
}

#main-menu {
    position: absolute;
    top: 30px;
    left: 0px;
    z-index: 99999999;
    background: #fff;
    padding: 1rem 0;
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2);
    border-radius: 3px;
    min-width: 200px;
}

#main-menu ul li a {
    padding: 4px 1rem;
    display: block;
}

#main-menu ul li a:hover {
    background: #f9f9f9;
}

/* Product menu */
#product-menu {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    z-index: 999999;
}

#product-menu-wrapper {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 2rem;
}

.product-menu-item:first-child {
    border-radius: 0 0 0 3px;
}

.product-menu-item {
    height: 45px;
    background: #fff;
    border-left: 2px solid #e7e7e7;
    text-align: center;
    line-height: 43px;
    cursor: pointer;
    text-decoration: none;
    color: #111;
    font-weight: 400;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    border-top: 1px solid #e7e7e7;
    border-bottom: 2px solid #e7e7e7;
    font-size: 0.875rem;
}

.product-menu-item:last-child {
    border-right: 2px solid #e7e7e7;
    border-radius: 0 0 3px 0;
}

.product-menu-item.active {
    background: #f9f9f9;
    border-radius: 0;
}

.product-menu-item:last-child:hover {
    border-radius: 0 0 5px 0;
}

#product-menu.fixedProductMenu, .fixedProductMenu {
    position: fixed;
    top: 0;
}

/* #product-menu.fixedProductMenu #product-menu-wrapper  { box-shadow: 0 0 20px 0 rgba(0,0,0,.12); } */
.fixedMobileProductMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999999;
}


/* Product menu submenu */
.product-menu-item {
    position: relative;
}

.product-menu-item.active .product-sub-menu {
    visibility: visible;
    opacity: 1;
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
}

.product-menu-item .product-sub-menu {
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
}

.product-sub-menu {
    width: calc(100% + 4px);
    visibility: visible;
    opacity: 1;
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
    position: absolute;
    top: 43px;
    left: -2px;
    min-width: 220px;
    padding: 10px 20px;
    background: none;
    z-index: 999999;
    visibility: hidden;
    -webkit-transition: 0.2s .2s;
    -o-transition: 0.2s .2s;
    transition: 0.2s .2s;
    opacity: 0;
    text-align: left;
    font-size: 0.875rem;
    background: #fff;
    -webkit-box-shadow: 0 15px 15px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, .1);
    border: 1px solid #e7e7e7;
    border-top: none;
    padding: .5rem .5rem 1rem;
    border-radius: 0 0 3px 3px;
}

.product-sub-menu a {
    color: #111;
    text-decoration: none;
    font-weight: normal;
    padding: .5rem;
}

.product-sub-menu a:hover {
    color: #B8970F;
}

.product-sub-menu .form-line {
    clear: both;
}

#menu-item-marki .product-sub-menu {
    width: calc(300% + 8px);
    line-height: 1.5;
}

.sub-menu-column {
    padding: 1rem .75rem;
    width: 25%;
    float: left;
}

#menu-item-marki .sub-menu-column a, #menu-item-segment .sub-menu-column a {
    display: block;
    clear: both;
}

#menu-item-marki .sub-menu-column a:hover, #menu-item-segment .sub-menu-column a:hover, #menu-item-budzet .product-sub-menu ul li a:hover {
    background: #f9f9f9;
}

#menu-item-marki .sub-menu-column a img {
    max-width: 20px;
    margin-right: 10px;
}

.clearfix {
    clear: both;
    text-align: center;
    padding-bottom: 0.5rem;
}

.product-sub-menu form {
    width: 100%;
    padding: .5rem;
}

.product-sub-menu form button {
    width: 100%;
    border-radius: 3px;
    height: 40px;
    line-height: 40px;
}

#menu-item-segment .product-sub-menu {
    width: calc(300% + 4px);
    line-height: 1.5;
}

#menu-item-segment .sub-menu-column {
    padding: 1rem;
    width: 50%;
    float: left;
}

#menu-item-segment .sub-menu-column a img {
    max-width: 35px;
    margin-right: 10px;
}

#menu-item-budzet .product-sub-menu {
    line-height: 1.5;
}

#menu-item-budzet .product-sub-menu ul {
    padding: .5rem;
}

#menu-item-budzet .product-sub-menu ul li a {
    font-weight: normal;
    display: block;
    padding: .5rem;
}

/* Fixy */
#menu-item-budzet .product-sub-menu .half-select select, #config-modal .half-select select {
    display: block;
    float: left;
}

#menu-item-budzet .product-sub-menu .half-select select, #config-modal .half-select select {
    width: calc(50% - 10px) !important;
    margin-bottom: .5rem;
}

#menu-item-budzet .product-sub-menu .half-select select:nth-child(1), #config-modal .half-select select:nth-child(1) {
    margin-right: 20px;
}

/*.margin-left-50 {*/

/*}*/

.bp3-menu {
    min-width: 140px;
}

/*Rata od - Rata do i wybieranie mocy silnika*/
.simpleSuggest {
    min-width: 80px;
}

.simpleSuggest input{
    padding: 10px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: none;
    border-bottom: 3px solid rgba(0, 0, 0, .15) !important;
}

.half-select {
    display: flex;
    justify-content: space-between;
}

.simpleSuggest:last-child {
    margin-left: 1rem;
}

.product-sub-menu button {
    max-width: 220px;
    margin: 0 auto;
}

.checkbox-label {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: .5rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.checkbox-label img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

#menu-item-marki .product-sub-menu .checkbox-label img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

#menu-item-segment .product-sub-menu .checkbox-label img {
    width: 35px;
    height: 12px;
    margin-right: 10px;
}

.wszystkieMarki-label, .all-items-label {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

input[type="checkbox"] {
    margin-bottom: 0;
}

.top-search {
    width: 300px;
}

.topFormBody {
    height: 40px;
    line-height: 40px;
}

.top-search input[type="search"], .topSearchInput input {
    width: calc(100% - 40px);
    border-right: none;
    padding: 0 .5rem;
    height: 40px;
}

.top-search input {
    -webkit-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
}

.top-search button, .topSearchButton button {
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    -webkit-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    margin-left: auto;
    background: #176FB7;
}

.top-search button:after, .topSearchButton button:after {
    display: none;
}


/* Content */
#content ul, #content ol, #content table, #content blockquote {
    margin: 0 0 2rem;
}

p, #content p {
    margin: 0 0 1rem;
}

#content .description p {
    margin: 0;
}

#content ul, #content ol {
    padding-left: 2.5rem;
}

#content ul {
    list-style-type: disc;
}

#content blockquote p:last-child {
    margin-bottom: 0;
}

#content blockquote {
    background: #f9f9f9;
    padding: 2rem;
}

img {
    max-width: 100%;
    vertical-align: middle;
    height: auto;
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

table {
    width: 100%;
    margin-bottom: 20px;
}

td, th {
    border: 1px solid #e7e7e7;
    padding: 10px;
    text-align: left;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 1.75rem;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1.25rem;
}

h5 {
    font-size: 1.125rem;
}

h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    margin: 0 0 2rem;
    font-weight: 900;
}

.advancedSearch {
    height: 30px;
    color: #fff;
    text-decoration: underline;
    margin: 1rem 0 0;
    font-weight: 900;
}

.advancedSearch:hover {
    background-color: #333;
}

.center {
    text-align: center;
}

/* Slider basics */
.owl-dot {
    margin: 0 4px;
    display: inline-block;
}

.owl-dot span {
    display: block;
    width: 16px;
    height: 16px;
    background: #ccc;
    border-radius: 8px;
    -webkit-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s;
}

.owl-dot.active span, .owl-dot span:hover {
    background: #176FB7;
    -webkit-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s;
}

.owl-dots {
    text-align: center;
    margin-top: 10px;
}

.owl-prev, .owl-next {
    position: absolute;
    top: 50%;
    width: 36px;
    height: 36px;
    margin-top: -36px;
    text-align: center;
    z-index: 99999;
    border-radius: 18px;
    text-indent: -999rem;
}

.container .owl-carousel .owl-nav .owl-prev {
    left: 20px;
    background: url(assets/images/icons/arrow_left.svg) center center no-repeat #176FB7;
    background-size: 12px 12px;
}

.container .owl-carousel .owl-nav .owl-next {
    right: 20px;
    background: url(assets/images/icons/arrow_right.svg) center center no-repeat #176FB7;
    background-size: 12px 12px;
}

/* Home main slider + search form */
#home-intro {
    padding: 160px 0 80px;
    background-size: cover;
    animation: bgsize 8s linear;
    background-position: center center !important;
    -webkit-animation-fill-mode: forwards;
}

@-webkit-keyframes bgsize {
    0% {
        background-size: 100%;
    }
    /* 50% { background-size:  110%; } */
    100% {
        background-size: 110%;
    }
}

@keyframes bgsize {
    0% {
        background-size: 100%;
    }
    /* 50% { background-size:  110%; } */
    100% {
        background-size: 110%;
    }
}


#search-form {
    flex-basis: 50%;
    padding: 0 0 0 100px;
    margin-bottom: 50px;
}

#search-form form {
    max-width: 400px;
    border-radius: 3px;
    background: rgba(23, 111, 183, 0.68);
    background: rgba(0, 0, 0, .7);
    color: #fff;
    padding: 2rem;
    text-align: center;
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, .2);
    margin-left: 50px;
}

#search-form form select, #search-form button {
    width: 100%;
    border-radius: 3px;
    border: none;
}

#search-form h3 {
    margin-bottom: 1rem;
}

#search-form form select {
    background: url(assets/images/icons/arrow_circle_bottom.svg) 94% center no-repeat #fff !important;
    background-size: 14px auto !important;
    width: calc(50% - 10px);
    float: left;
}

#search-form form select:nth-child(odd) {
    margin-left: 10px;
}

#search-form form select:nth-child(even) {
    margin-right: 10px;
}

#home-intro-slider {
    clear: both;
    background: rgba(0, 0, 0, .7);
    padding: 1rem;
    color: #fff;
    border-radius: 3px;
}

#home-intro-slider .slide {
    padding: 0 20px;
}

#home-intro-slider strong {
    font-size: 1rem;
}

#home-intro-slider span {
    display: block;
    font-size: 0.875rem;
}

#home-intro-slider .slide img {
    max-width: 30px;
    margin-right: 10px;
}

/* Fixy search form */
#search-form .ms-parent {
    width: 100% !important;
    margin-right: 0;
}

#search-form .half-select select, .offer-list-drawer .half-select select {
    width: calc(50% - 10px) !important;
    float: left;
    background: url(assets/images/icons/arrow_circle_bottom.svg) 95% center no-repeat #fff !important;
    background-size: 14px auto !important;
}

#search-form .half-select select:nth-child(1), .offer-list-drawer .half-select select:nth-child(1) {
    margin-right: 20px;
    margin-left: 0;
}

.half-select .inputStyle {
    width: calc(50% - 10px) !important;
    float: left;
}

.half-select .inputStyle:nth-child(1) {
    margin-right: 20px;
    margin-left: 0;
}

.half-select .inputStyle:nth-child(2) {
    margin:0;
}

#search-form .half-select select:nth-child(2), .offer-list-drawer .half-select select:nth-child(2) {
    margin: 0;
}


/* Home sections */
#home-icons {
    padding: 3rem 0;
}

#home-icons .flex, #usp .flex {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.home-icon, .usp-item, .home_banner {
    -ms-flex-preferred-size: 23%;
    flex-basis: 23%;
    text-align: center;
}

.home-icon img {
    max-width: 100px;
    margin-bottom: 2rem;
}

.home-icon p {
    margin-bottom: 0;
}

#home-text {
    background: #f9f9f9;
    padding: 2rem 0;
}

#home-text-content {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: justify;
}

#home-text div.iframe {
    -ms-flex-preferred-size: 44%;
    flex-basis: 44%;
    margin-left: auto;
}

#quotes, #marki, #testimonials {
    padding: 2rem 0;
}

#hot-offers-slider {
    padding: 0 2rem;
    margin-bottom: 1rem;
}

#hot-offers-bg, #similiar-offers-bg {
    background: #ccc;
    padding: 2rem 0 .5rem;
}

#hot-offers-bg {
    margin-bottom: 2rem;
}

.offer {
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    background: #fff;
    position: relative;
}

a.offer{
    display: block;
}

.offer-top {
    text-align: center;
    padding: 1rem .5rem;
}

.offer-top > span {
    display: block;
}

.offer-points, .offer-bottom-points {
    font-size: 0.8rem;
}

.offer-top span.offer-points{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.offer-points span{
    font-size: 0.8rem;
    text-align: left;
}

.offer-points span:nth-child(even){
    flex-basis: 45%;
}

.offer-points span:nth-child(odd){
    flex-basis: 55%;
    padding-left: 6px;
}

.offer-title {
    margin-bottom: .5rem;
    font-size: 1.125rem;
    height: 54px;
    overflow: hidden;
    color: #B8970F;
    text-decoration: none;
    font-weight: 900;
    cursor: pointer;
}

.offer-title-contact {
    margin-bottom: .5rem;
    font-size: 1.125rem;
    height: 54px;
    overflow: hidden;
    color: #B8970F;
    text-decoration: none;
    font-weight: 900;
}

.offer-text {
    margin-bottom: .5rem;
}

.offer-img div:last-child {
    font-size: .75rem;
    color: #B8970F;
    text-decoration: none;
    text-align: center;
    display: block;
    height: 100%;
    width: 100%;
}

.offer-img img, .offer-img .ribbon-hot, .offer-img .ribbon-od-reki {
    cursor: pointer;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.offer-bottom-price {
    font-weight: 900;
    font-size: 1.125rem;
    margin-bottom: .5rem;
}

.offer-bottom {
    text-align: center;
    padding: 1rem;
}

.price {
    color: #FF2800;
}

#hot-offers-slider .owl-dots {
    margin-top: 20px;
}

#usp, #others {
    padding: 3rem 0 3.5rem;
    background: #f9f9f9;
}

.usp-item, .home_banner {
    position: relative;
    min-height: 200px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

#home-banners {
    background: #f9f9f9;
    padding: 2rem 0;
}

.home_banners_wrapper {
    justify-content: space-between;
}

.home_banner {
    margin-bottom: 1rem;
}

.home_banner small {
    font-size: .75rem;
}

.usp-item .btn {
    width: auto;
    padding: 0 .75rem;
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: .875rem;
    height: 30px;
    line-height: 30px;
}

.usp-item .btn:after {
    width: 12px;
    height: 12px;
    margin-left: 5px;
}

.usp-item img {
    padding: 1rem 0;
}

.usp-item h6, .home_banner span {
    position: absolute;
    bottom: 40px;
    right: 10px;
    background: rgba(0, 0, 0, .5);
    padding: .5rem 1rem;
    margin: 0;
    color: #fff;
}

.home_banner span {
    font-weight: bold;
    font-size: 1.125rem;
    bottom: 36px;
    padding: .25rem 1rem;
}

.home_banner small {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: rgba(0, 0, 0, .5);
    padding: .25rem 1rem;
    margin: 0;
    color: #fff;
}

/*.usp-item h5, .usp-item p { margin-bottom: 1rem; } */
#quotes .flex {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.quote-item {
    -ms-flex-preferred-size: 23%;
    flex-basis: 23%;
}

.quote-content {
    background: url(assets/images/icons/quote.svg) top right no-repeat;
    background-size: 80px auto;
    padding: 1rem 1rem 0 0;
    font-size: .875rem;
    text-align: justify;
}

.quote-author {
    text-align: center;
}

.quote-author p {
    font-weight: 900;
    margin-bottom: 0;
}

.quote-author span {
    font-size: 0.875rem;
    color: #666;
}

.author-logo {
    max-width: 150px;
    display: block;
    margin: 0 auto;
}

#logos {
    padding: 2rem 0 3rem 0;
}

#logos-slider .owl-dots, #testimonials-slider .owl-dots {
    margin-top: 30px;
}

.logo-slide img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.logo-slide img:hover {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%)
}

#testimonials-slider {
    padding: 0 3rem;
}

#testimonials-slider .owl-prev, #logos-slider .owl-prev, #hot-offers-slider .owl-prev {
    left: -25px;
}

#testimonials-slider .owl-next, #logos-slider .owl-next, #hot-offers-slider .owl-next {
    right: -25px;
}

.testimonial img {
    border-radius: 3px;
    border: 1px solid #e7e7e7;
}

#marki .flex {
    justify-content: space-between;
}

#marki a {
    flex-basis: 12%;
    padding: .75em;
    text-align: center;
    display: block;
    font-size: 0.875rem;
    text-decoration: none;
    color: #111;
    border-radius: 3px;
    font-size: .75rem;
    text-align: center;
    margin-bottom: 1rem;
}

#marki a img {
    max-width: 60px;
    display: block;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    margin: 0 auto;
}

#marki a:hover {
    background: #f9f9f9;
}

#marki a:hover img {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}

#others .flex {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.other {
    border: 1px solid #e7e7e7;
    background: #fff;
    padding: 1rem 1rem 2rem 1rem;
    -ms-flex-preferred-size: 23%;
    flex-basis: 23%;
    text-align: center;
    border-radius: 3px;
}

.other img {
    max-width: 200px;
    display: block;
    margin: 0 auto 1rem;
}

.other span {
    display: block;
    text-align: center;
    margin-bottom: 1rem;
}

a.readmore {
    font-weight: 900;
    text-decoration: none;
    color: #176FB7;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

a.readmore:after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background: url(assets/images/icons/arrow_right_blue.svg) center center no-repeat;
    background-size: auto 100%;
    margin-left: 10px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}


/* Forms */
input, textarea, select {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input, select {
    height: 40px;
    line-height: 40px;
    padding: 0 0 0 .5rem;
    margin: 0 0 1rem;
    color: #111;
    font-size: 0.875rem;
    font-weight: 400;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: block;
}

input[type="checkbox"] {
    min-width: 16px;
    height: 16px;
    float: left;
    margin-right: .5rem;
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox;
}

input[type="radio"] {
    -webkit-appearance: radio;
    -moz-appearance: radio;
    appearance: radio;
}

textarea {
    padding: .5rem;
    margin: 0 0 1rem;
    color: #111;
    font-size: 0.875rem;
    font-weight: 400;
    border: 1px solid #ccc;
    border-radius: 3px;
    display: block;
}

input:focus, select:focus, textarea:focus {
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .2);
}

button {
    height: 50px;
    line-height: 50px;
    background: #B8970F;
    color: #fff;
    font-weight: 900;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 3px;
}

button:hover {
    background: #333;
}

button:after, #single-right form button#send-form:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: url(assets/images/icons/arrow_circle_right_white.svg) center center no-repeat;
    background-size: auto 100%;
    margin-left: 10px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

button.ms-choice:after {
    display: none;
}

select {
    width: 100%;
    background: url(assets/images/icons/arrow_circle_bottom.svg) 93% center no-repeat #fff !important;
    background-size: 14px auto !important;
}

input::placeholder, select.placeholder {
    color: rgba(92, 112, 128, 0.6)
}

select option {
    color: #000000
}

/* Newsletter */
#newsletter {
    background: #176FB7;
    color: #fff;
    padding: 3rem 0;
}

#newsletter h4 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    margin: 0;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#newsletter form {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    width: 100%;
    background: #fff;
    border-radius: 3px;
    overflow: hidden;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

#newsletter form button {
    border: none;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    -webkit-border-radius: 0;
    border-radius: 0;
}

#newsletter form input {
    border: none;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    margin: 0;
    height: 50px;
}


/* Faq */
#faq {
    padding: 3rem 0;
}

.faq-item {
    border-bottom: 1px solid #e7e7e7;
}

.faq-question {
    font-size: 1.125rem;
    color: #176FB7;
    font-weight: 900;
    padding: 1rem 0;
    cursor: pointer;
    text-align: center;
}

.faq-question p {
    margin-bottom: 0;
}

.faq-answer {
    display: none;
    padding: 1rem 2rem 1rem;
    margin: 0;
}


/* Footer */
footer {
    padding: 4rem 0 0;
    background: #222;
    color: #fff;
}

footer .container {
    padding: 0 0 4rem;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

footer a {
    color: #fff;
    text-decoration: none;
}

footer li {
    margin-bottom: 5px;
}

.social a {
    margin: 5px 10px 5px 0;
}

.social a:hover {
    opacity: .6
}

.social a img {
    max-width: 30px;
}

.seo-text {
    text-align: justify;
    font-size: 0.875rem;
}
.seo-text-certs {
    text-align: justify;
    font-size: 0.875rem;
    max-width: 600px;
    float: right;
    display: flex;
    margin-right: 9%;
    justify-content: unset !important;
}

.seo-text-certs img {
    padding-bottom: 20px;
    max-width: 130px;
    margin-right: 1rem;
}

.pearls {
    margin-top: 5px;
}

.trustCompany img {
    width: 105px;
}

.reliableCompany {
    margin-top: -12px;
    margin-left: -25px;
}

.reliableCompany img{
    width: 268px;
    max-width: 900px;
}

#copyright {
    padding: 2rem 1rem;
    text-align: center;
    background: #fff;
}

#copyright p {
    margin-bottom: 0;
}

footer .hours {
    margin-bottom: 2rem;
}

footer .contact-data {
    margin-bottom: 1rem;
}


/* Cookies */
#cookies {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #f7f7f7;
    font-size: 0.875rem;
    line-height: 1.4;
    z-index: 99999;
}

.cookies-text {
    margin: 5px 20px 0 0;
}

.cookies-inner {
    padding: 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-line-pack: center;
    align-content: center;
}

.cookies-close {
    margin-left: auto;
    color: #fff !important;
    background: green;
    padding: 5px 10px;
    text-decoration: none;
    border-radius: 2px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.cookies-close:hover {
    opacity: .8;
}

#gotop {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 18px;
    background: url(assets/images/icons/logo_icon_top.svg) center center no-repeat #176FB7;
    background-size: 36px 36px;
    width: 36px;
    height: 36px;
    text-align: center;
    z-index: 9999999;
}


/* Header toggle */
.can-toggle {
    position: relative;
}

.can-toggle *, .can-toggle *:before, .can-toggle *:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.can-toggle input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.can-toggle input[type="checkbox"][disabled] ~ label {
    pointer-events: none;
}

.can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
    opacity: 0.4;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
    content: attr(data-unchecked);
    left: 0;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    content: attr(data-checked);
}

.can-toggle label {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
}

.can-toggle label .can-toggle__switch {
    position: relative;
}

.can-toggle label .can-toggle__switch:before {
    content: attr(data-checked);
    position: absolute;
    top: 0;
    text-align: center;
}

.can-toggle label .can-toggle__switch:after {
    content: attr(data-unchecked);
    position: absolute;
    z-index: 5;
    text-align: center;
    background: #176FB7;
    color: white;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.can-toggle input[type="checkbox"][disabled] ~ label {
    color: #111;
}

.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch {
    background-color: #f9f9f9;
}

.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
    color: #fff;
}

.can-toggle input[type="checkbox"]:hover ~ label {
    color: #fff;
}

.can-toggle input[type="checkbox"]:checked ~ label:hover {
    color: #176FB7;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
    background-color: #f9f9f9;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    color: #fff;
}

.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
    background-color: #f9f9f9;
}

.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
    color: #fff;
}

.can-toggle label .can-toggle__label-text {
    -ms-flex: 1;
    -webkit-box-flex: 1;
    flex: 1;
}

.can-toggle label .can-toggle__switch {
    -webkit-transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
    -o-transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
    transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
}

.can-toggle label .can-toggle__switch:before {
    color: #111;
    background: #f9f9f9;
}

.can-toggle label .can-toggle__switch:after {
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
    transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
    -o-transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
    transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
    transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1), -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
    color: #fff;
}

.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
    /* box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); */
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    -webkit-transform: translate3d(78px, 0, 0);
    transform: translate3d(78px, 0, 0);
}

.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after { /* box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); */
}

.can-toggle label {
    font-size: 0.75rem;
}

.can-toggle label .can-toggle__switch {
    height: 30px;
    -ms-flex: 0 0 158px;
    -webkit-box-flex: 0;
    flex: 0 0 158px;
    border-radius: 0px;
    cursor: pointer;
}

.can-toggle label .can-toggle__switch:before {
    left: 78px;
    font-size: 0.75rem;
    line-height: 30px;
    width: 80px;
    padding: 0 12px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.can-toggle label .can-toggle__switch:after {
    top: 0px;
    left: 0px;
    border-radius: 2px;
    width: 78px;
    line-height: 30px;
    font-size: 0.75rem;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.can-toggle label .can-toggle__switch:hover:after { /* box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); */
}


/* Podstrona */
#page-intro {
    padding: 100px 0;
    min-height: 350px;
    text-align: center;
    color: #fff;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
}

#page-intro h1 {
    margin: 0;
}

#content {
    margin: 2rem 0 4rem;
}

#content a:hover {
    text-decoration: underline;
}

#content .container, .side-padding {
    padding: 0 2rem;
}

#page-intro a {
    color: #B8970F;
}

#page-intro a i {
    color: #fff;
}

.breadcrumb {
    margin-bottom: 1rem;
}

.breadcrumb a, .breadcrumb span {
    padding: 0 10px;
}

.breadcrumb a img {
    max-width: 14px;
}

#page-intro .container {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.page-intro-text p {
    margin-top: 1rem;
    font-size: 1.125rem;
    margin: 0 0 0.2rem;
}

#content .faq-question p {
    margin: 0;
}

.page-faq .faq-question {
    text-align: left;
}

.page-faq .faq-items {
    margin-bottom: 3rem;
}

#content ul ul, #content ol ul, #content ul ol, #content ol ol {
    padding-left: 1rem;
}

#contact-form {
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    padding: 2rem;
    margin-left: auto;
}

#send-phone-form {
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    padding: 2rem;
}

#contact-content, #contact-form, #send-phone-form {
    -ms-flex-preferred-size: 45%;
    flex-basis: 45%;
    margin-top: 2rem;
}

#contact-form input, #contact-form textarea, #contact-form button,
#send-phone-form input, #send-phone-form textarea, #send-phone-form button,
.order-form input, .order-form textarea, .order-form button {
    width: 100%;
    border-radius: 3px;
}

.order-form ul{
    list-style: disc;
    margin-left: 1.5rem;
}

.order-form p{
    margin: 1rem 0;
}

/*#contact-form span { margin-bottom: .5rem; display: block; }*/
#contact-form input[type="text"], #contact-form input[type="tel"], #contact-form input[type="email"],
#send-phone-form input[type="text"], #csend-phone-form input[type="tel"], #send-phone-form input[type="email"],
.order-form input[type="text"], .order-form input[type="tel"], .order-form input[type="email"]
{
    height: 40px;
    margin-bottom: .5rem
}

#contact-form input[type="checkbox"], #send-phone-form input[type="checkbox"], .order-form input[type="checkbox"] {
    width: 16px;
    height: 16px;
}

#contact-form select, #send-phone-form select, .order-form select {
    height: 40px;
    border: 1px solid #ccc !important;
    border-radius: 3px;
    margin-bottom: .5rem;
}

.contact-data img.contact-logo {
    max-width: 180px;
    margin-right: 2rem;
}

.contact-data-text {
    margin-left: auto;
    font-size: 1.125rem;
}

.contact-data-text img.contact-mail {
    max-height: 30px;
    max-width: 30px;
    text-align: center;
    -ms-flex-preferred-size: 50px;
    flex-basis: 50px;
}

.contact-data-text img.contact-phone {
    max-height: 24px;
    max-width: 30px;
    text-align: center;
    -ms-flex-preferred-size: 50px;
    flex-basis: 50px;
}

.contact-data-text strong {
    margin-left: 1rem;
}

.rodo-checkbox-wrapper input[type="checkbox"] {
    float: left;
    margin-top: 3px;
}

.rodo-checkbox-wrapper span {
    display: block;
    float: right;
    width: calc(100% - 34px);
    font-size: .75rem;
}

.rodo-checkbox-wrapper {
    margin-bottom: 1rem;
    display: block;
    overflow: hidden;
}

label.bp3-checkbox {
    font-size: .75rem
}

/* Lista ofert */

div.lista-ofert {
    background: #ccc;
}

div.lista-ofert #page-intro{
    padding: 50px 0;
    min-height: 200px;
}

#sorting-bar {
    background: #f9f9f9;
    padding: 1rem 0;
}

#sorting-bar .container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

#sorting-wrapper {
    display: none;
}

#sorting-bar.fixedSortingBar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    padding: .5rem 0;
    border-bottom: 1px solid #e7e7e7;
}

.btn i {
    margin-right: 10px;
}

#fiter-toggle.btn, #sorting-toggle.btn {
    margin: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: auto;
    padding: 0 2.5rem;
    background: #fff;
    color: #111;
    border: 1px solid #e7e7e7;
    border-bottom: 3px solid #e7e7e7;
}

#fiter-toggle.btn:hover, #sorting-toggle.btn:hover {
    background: #B8970F;
    border-color: #9c800c;
    color: #fff;
}

#fiter-toggle.btn:after, #sorting-toggle.btn:after {
    display: none;
    margin: 0;
}

.view-toggle-wrapper-class {
    padding-left: calc(60% - 280px);
}

#view-toggle-wrapper > span {
    cursor: pointer;
    margin: 0 2rem;
    opacity: .3;
}

#view-toggle-wrapper > span:hover {
    opacity: 1
}

#view-toggle-wrapper > span.active {
    opacity: 1
}

#offers-list.grid-view {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

#offers-list.grid-view .offer, #offers-list.grid-view .version-popover {
    margin: 0 2% 1.5rem 0;
    -ms-flex-preferred-size: 23.5%;
    flex-basis: 23.5%;
}

#offers-list.grid-view .offer:nth-child(4n+4), #offers-list.grid-view .offer:last-child, #offers-list.grid-view .version-popover:nth-child(4n+4), #offers-list.grid-view .version-popover:last-child {
    margin: 0 0 1.5rem 0;
}

#offers-list.grid-view:after {
    content: '';
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    -ms-flex-preferred-size: 23%;
    flex-basis: 23%;
    margin: 0 2% 1.5rem 0;
}

#content .offer ul {
    padding: 0;
    margin: 0;
}

#offers-list.list-view {
    display: block;
    padding-bottom: 10px;
}

#offers-list.list-view .offer {
    margin: 0 0 .5rem 0;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow: hidden;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    position: relative;
}

#offers-list.list-view .offer .bp3-popover-target {
    flex-basis: 100%;
}

#offers-list.list-view .inner-offer {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow: hidden;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    position: relative;
}

#offers-list.list-view .offer .offer-img {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    -ms-flex-preferred-size: 15%;
    flex-basis: 15%;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; /* overflow: hidden; */
}

#offers-list.list-view .offer .offer-img img {
    border-radius: 3px 0 0 3px;
}

#offers-list.list-view .offer .offer-bottom {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    -ms-flex-preferred-size: 10%;
    flex-basis: 10%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
}

#offers-list.list-view .offer .offer-bottom .full-price {
    margin: 0 0 .5rem 0;
}

#offers-list.list-view .offer .offer-top {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    text-align: left;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    padding: 1rem 1rem;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
}

#offers-list.list-view .offer-top .offer-title {
    font-size: 1.25rem;
}

#offers-list.grid-view .offer-list-view, #offers-list.grid-view .offer-list-view-price {
    display: none;
}

#offers-list.list-view .offer-list-view {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: block;
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: end;
    align-content: flex-end;
    padding: 1rem 0;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

#offers-list.list-view .offer-list-view .ribbon {
    height: 34px;
    line-height: 34px;
    border-radius: 3px;
}

#offers-list.list-view .offer-list-view .ribbon.hot-label {
    color: #fff;
    background: #FB0004;
    padding: 0 .5rem;
    margin-right: 1rem;
    text-transform: uppercase;
    font-weight: bold;
}

#offers-list.list-view .offer-list-view .ribbon.od-reki-label {
    color: #fff;
    background: #23932F;
    width: 130px;
    padding: 0 .5rem;
    margin-right: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
}

#offers-list.list-view .offer-list-view .btn {
    margin-left: auto;
}

#offers-list.list-view .offer .offer-top .offer-title, #offers-list.list-view .offer .offer-top .offer-text, #offers-list.list-view .offer .offer-top .offer-points {
    -ms-flex-preferred-size: 80%;
    flex-basis: 80%;
}

#offers-list.list-view .offer .offer-top .offer-title {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}

#offers-list.list-view .offer .offer-top .offer-text {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
}

#offers-list.list-view .offer .offer-top .offer-points {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
}

#offers-list.list-view .offer .offer-top .full-price.offer-list-view-price {
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
    margin: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.offer-img div:first-child {
    position: relative;
    display: block;
}

.ribbon-hot {
    position: absolute;
    top: -5px;
    left: -5px;
    background: url(assets/images/hot-oferta-ribbon.svg) center center no-repeat;
    background-size: 100% auto;
    width: 130px;
    height: 130px;
}

#offers-list.list-view .ribbon-hot, #offers-list.list-view .ribbon-od-reki {
    display: none;
}

.offer-title, .offer-text {
    margin-bottom: 0;
}

.offer .offer-img div:last-child {
    margin: 0;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#offers-list .offer-bottom {
    padding: 1rem;
    padding-left: 0.7rem;
}

.offer-btn {
    width: auto;
    padding: 0 1rem;
    margin: 0;
    height: 40px;
    line-height: 40px;
}

.offer-contact-btn {
    width: 100%;
    padding: 0 1rem;
    margin: 0;
    height: 40px;
    line-height: 40px;
}

.offer-bottom.flex {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.full-price {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.2;
    text-align: left;
}

.full-price small {
    font-size: .75rem;
    grid-column: 3;
    clear: both;
    display: block;
}

.full-price span {
    color: #FF2800;
    font-weight: 900;
}

.sorting-label {
    white-space: nowrap
}

.onlymobile {
    display: none;
}

#hot-offers-mobile-button {
    margin-bottom: 3rem;
}

#hot-offers-btn {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.overlay {
    background: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    z-index: 99999;
    position: fixed;
    top: 0;
    opacity: 0;
}

.overlay-content {
    padding: 1rem;
    width: 380px;
    background: #fff;
    position: absolute;
    top: 0;
    height: 100vh;
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .4);
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, .4);
    max-width: 90%;
}

#filters-overlay .overlay-content {
    left: 0;
    right: auto;
    overflow-y: scroll;
}

#sorting-overlay .overlay-content {
    right: 0;
    left: auto;
}

#filters-overlay {
    left: -150%;
}

#sorting-overlay {
    right: -150%;
}

.overlay-header {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 0rem;
    margin-bottom: .5rem;
}

.overlay-header p {
    margin-bottom: .5rem;
}

.overlay-header .closeOverlay {
    margin-left: auto;
    cursor: pointer;
}

#filters-overlay button[type="button"], #sorting-overlay button[type="button"], .offer-list-drawer button {
    width: 100%;
    border-radius: 3px;
    margin-top: 1rem;
    margin-bottom: 0rem;
}

#sorting-overlay label {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    clear: both;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    line-height: 40px;
}

#sorting-overlay input {
    margin: 0 .5rem 0 0;
}

.offer-list-drawer .half-select {
    width: 100% !important;
    float: left;
}

.offer-list-drawer {
    padding: 1rem;
    overflow: auto
}

.offer-not-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    transition: background-color 0.5s;
    z-index: 1;
}

.popover-margin {
    margin-bottom: 370px !important;
    position: relative;
}

.popover-content {
    position: absolute;
    left: 0;
    width: 86vw;
    display: flex;
    justify-content: center;
    z-index: 99;
    animation: appearing 0.2s ease-in-out;
}

.version-list .owl-carousel {
    padding: 0 2rem;
}

.container .version-list .owl-carousel .owl-nav .owl-prev {
    left: 0;
    top: 55%;
}

.container .version-list .owl-carousel .owl-nav .owl-next {
    right: 0;
    top: 55%;
}

.vehicle-version-item {
    background: #ffffff;
    border: 1px solid #e7e7e7;
    border-radius: 3px;
}

#offers-list .vehicle-version-item .offer-bottom {
    padding: 12px 1rem;
}

#offers-list .vehicle-version-item .offer-top {
    padding: 12px .5rem;
}

.vehicle-version-item .offer-title {
    font-size: 0.9rem;
    height: 40px;
    border-bottom: 1px solid;
}

.vehicle-version-item .brand-name {
    font-size: 0.9rem;
    font-weight: 900;
}

.offer-description {
    padding: 0 1rem;
}

.offer-description div {
    margin-bottom: 3px;
}

.owl-carousel .owl-item img.description-icon, img.description-icon {
    width: 16px;
    display: inline-block;
    margin-right: 5px;
}


/* Pojedyncza oferta */
body.oferta {
    background: #ccc;
}

#single-intro {
    padding: 4rem 0 1rem;
    background: #f9f9f9;
    border-top: 1px solid #e7e7e7;
}

#single-intro a {
    color: #B8970F;
    text-decoration: none;
}

#single-intro a:hover {
    text-decoration: underline;
}

.single-heading-1 {
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.single-heading-1 h1 {
    margin: 0 0 0 0rem;
    line-height: 2rem;
    overflow-wrap: break-word;
}

.single-heading-info {
    margin-left: auto;
    text-align: right;
    font-size: .875rem;
}

.wartosc-katalogowa {
    font-weight: bold;
    margin-left: auto;
    font-size: .9rem;
    display: inline-block;
    width: 280px;
}

.wartosc-katalogowa span {
    color: #FF2800;
}

#single-left {
    -ms-flex-preferred-size: 67%;
    flex-basis: 67%;
}

#single-right {
    -ms-flex-preferred-size: 30%;
    flex-basis: 30%;
    margin-left: auto;
}

.single-cover {
    border-radius: 3px;
    margin-bottom: 1.5rem;
}

.single-box, #single-right {
    padding: 1rem;
    border-radius: 3px; /*border: 1px solid #e7e7e7;*/
    margin-bottom: 1rem;
    background: #fff;
    font-size: .875rem;
    overflow: hidden;
    position: relative;
}

#content .single-box table {
    margin-bottom: 0;
}

#content .single-box table tbody tr.selected {
    background-color: #176FB7;
    color: #ffffff;
}

#content .single-box h3 {
    margin-bottom: 1rem;
}

.line {
    justify-content: flex-start;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: .3rem;
    margin-bottom: .3rem;
}

.line-right {
    margin-left: auto;
    flex-shrink: 0
}

.column {
    margin-right: 2rem;
    min-width: 30%
}

.column:last-child {
    margin-right: 0;
}

.single-info .flex {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.line .line-right {
    color: #B8970F;
    font-weight: bold;
    text-align: right;
}

.single-models .table-toggle {
    margin-bottom: 1rem;
}

th:first-child {
    border-radius: 3px 0 0 0;
}

thead th {
    background: #f9f9f9;
}

.th-wersja {
    width: 25%;
}

.th-kolor {
    width: 25%;
}

.th-cena {
    width: 20%;
}

.th-rata {
    width: 20%;
}

.th-zobacz {
    width: 10%;
}

tr:hover {
    background: #f9f9f9;
}

.oferta #hot-offers {
    background: #ccc;
    padding: 1rem 0;
}

#hot-offers .owl-dot span {
    background: #fff;
}

#hot-offers .owl-dot.active span, #hot-offers .owl-dot span:hover {
    background: #176FB7;
}

.oferta #hot-offers h3 {
    margin-bottom: 1rem;
}

.tab-content {
    display: none;
}

.tab-content.current {
    display: block;
}

.tab-content strong {
    display: none;
}

#content ul.tabs {
    list-style: none;
    margin: 0;
    padding: 0;
}

#single-tabs {
    width: calc(100% + 2rem);
    margin: -1rem;
}

.tab-content {
    padding: 1.5rem;
}

.tab-link {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    background: #f9f9f9;
}

.tab-link.current {
    background: #176FB7;
    color: #fff;
}

.line h1, .line h2, .line h3, .line h4, .line h5, .line h6 {
    margin-bottom: 0;
}

#single-right hr {
    margin: 1rem 0;
}

#single-right .single-toggle {
    margin-bottom: 1rem;
}

#single-right h5 {
    margin-bottom: 1rem;
}

.before-form .full-price, .fixed-box-left .full-price {
    margin-left: auto;
}

.before-form .full-price span, .fixed-box-left .full-price span {
    font-size: 2rem;
}

.before-form .full-price span.before-price {
    font-size: 14px;
    display: block;
    color: #000000;
}

.form-item {
    margin-bottom: 1rem;
}

#content .form-item p {
    margin-bottom: .5rem;
}

button#send-form {
    width: 100%;
    margin-top: 1rem;
}

#single-right form button#send-form {
    width: 100%;
    background: #B8970F;
    margin: 1rem 0 0 0;
    color: #ffffff;
    border: none;
    flex-basis: unset;
}

#single-right form button[type="button"]:after {
    display: none;
}

#single-right form button[type="button"] {
    padding: 0 1rem;
    margin: 0 1rem 1rem 0;
    -ms-flex-preferred-size: calc(50% - .5rem);
    flex-basis: calc(50% - .5rem);
    background: #176FB7;
}


#single-right form .form-okres button[type="button"] {
    padding: 0;
    margin: 0 .5rem 1rem !important;
    -ms-flex-preferred-size: calc(20% - 1rem);
    flex-basis: calc(20% - 1rem);
    background: #176FB7;
}

#single-right form .form-okres button[type="button"]:first-child {
    /*margin-left: 0 !important;*/
}

#single-right form .form-okres button[type="button"]:last-child {
    margin-right: 0 !important;
}

#single-right form button[type="button"]:not(.active) {
    border: 1px solid #e7e7e7;
    border-bottom: 3px solid #e7e7e7;
    background: #fff;
    color: #111;
}

#single-right form button[type="button"]:nth-child(2n+2) {
    margin: 0 0 1rem 0;
}

#single-right form button[type="button"].fullwidth {
    width: 100%;
    margin-right: 0;
}

#single-right form button[type="button"] .flex {
    width: 100%;
}

#single-right .form-ubezpieczenie button[type="button"], #single-right .form-gap button[type="button"] {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    margin-right: 0;
}

#single-right h3, #single-right h4 {
    margin-bottom: 1rem;
}

input[type="range"] {
    -webkit-appearance: range;
    -moz-appearance: range;
    appearance: range;
    height: 2px;
    margin: 1rem 0;
    padding: 0;
    width: 100%;
}

.range-wrap {
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
}

.bubble {
    background: #176FB7;
    color: white;
    padding: 4px 12px;
    position: absolute;
    border-radius: 3px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

#fixed-cta {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
}

.fixed-box {
    float: right;
    width: 30%;
    background: #111;
    border-radius: 3px 3px 0 0;
    color: #fff;
    padding: 1rem;
}

.fixed-box-left p {
    font-size: 0.75rem;
}

.fixed-box-left span {
    line-height: 1.4;
    padding-top: 2px;
}

.fixed-box-right {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    margin-left: auto;
}

.fixed-box-right .full-price {
    font-size: 2.5rem;
    line-height: 1;
}

.fixed-box-right .full-price span {
    font-size: 2.5rem;
    color: #B8970F;
}

.fixed-box-right .full-price small {
    text-align: right;
}

.fixed-box-left {
    -ms-flex-preferred-size: 45%;
    flex-basis: 45%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.fixed-box-left a, .fixed-box-left button {
    color: #B8970F;
    text-decoration: none;
}

.fixed-box-left a:hover, .fixed-box-left button:hover {
    text-decoration: underline;
}

#content .fixed-box-left a.btn {
    background: #fff;
    width: auto;
    padding: 0 1rem;
    color: #111;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    margin-top: auto;
}

#content #single-right form .fixed-box-left button {
    background: #fff;
    width: auto;
    padding: 0 1rem;
    color: #111;
    justify-content: center;
    align-content: center;
    margin: auto 0 0 0;
    border: none;
    flex-basis: unset;
}

.fixed-box-left a.btn i, .fixed-box-left button i {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.fixed-box-left a.btn:after, .fixed-box-left button:after {
    display: none;
}

.fixed-box-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

body.oferta #content {
    margin: 1rem 0;
}

.td-zobacz, .th-zobacz {
    text-align: center;
}

.td-zobacz .btn:after {
    margin: 0;
}

.td-zobacz .btn {
    height: 34px;
    line-height: 34px;
    max-width: 50px;
}

.form-fixed-box {
    color: #fff;
    padding: 1rem;
}

#form-submit {
    -webkit-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
    position: fixed;
    bottom: 0;
    left: 50%;
    z-index: 99999;
    margin-left: 244px;
    max-width: 364px;
    background: #111;
}

#form-submit.staticSubmit {
    position: static;
    margin: 0 -1rem -1rem;
    padding: 0;
    max-width: calc(100% + 2rem);
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.oferta #content .container.flex {
    align-items: flex-start;
}

.form-summary {
    margin-bottom: 1rem;
}


.single-cover {
    position: relative;
}

.single-cover > .cover-img {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    border: 2px solid rgba(128, 128, 128, 0.2);
}

.preview-foto {
    display: inline;
    font-style: italic;
    text-align: right;
    font-size: 13px;
    margin-right: 4px;
    max-width: 250px;
    margin-top: 3px;
}

 .offer-id-foto {
     display: inline;
     font-style: italic;
     text-align: right;
     font-size: 13px;
     margin-right: 4px;
     max-width: 250px;
     float:right;
     margin-top: 3px;
 }

.single-cover .gwarancja-zwrotu {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999;
    max-height: 30px;
    color: #FF2800;
    background: #fdeb69;
    padding: 3px;
}

.single-cover .gwarancja-zwrotu img {
    max-width: 24px;
    margin-right: .5rem;
}

.single-cover .ribbon-od-reki {
    top: 60px;
    z-index: 999;
}


.formLabel {
    background: #B8970F;
    color: #fff;
    padding: .5rem;
    font-size: 0.6875rem;
    text-transform: uppercase;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    margin: 0 .5rem 1rem;
}

.formLabel:first-child {
    margin-left: 0;
}

.formLabel:last-child {
    margin-right: 0;
}

.changeForm {
    color: #666;
    text-decoration: underline;
    font-size: .75rem;
    cursor: pointer;
}

.changeForm:hover {
    text-decoration: none;
}

#formWykup {
    padding: 1rem;
    border: 2px solid #176FB7;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.buttonIcon {
    flex-basis: 24px;
    margin-right: .5rem;
}

#single-right form button[type="button"] .flex span:last-child, .lastButton {
    margin-left: auto;
}

#single-right form button[type="button"].formButtonIcon .buttonIcon:before {
    content: '\f096';
    font-family: FontAwesome;
    font-size: 1rem;
    display: block;
    width: 24px;
    height: 24px;
}

#single-right form button[type="button"].formButtonIcon.active .buttonIcon:before {
    content: '\f046';
}

#single-right form button[type="button"].formButtonIcon.disabled {
    background-color: #e9e6e6;
}

#single-right form button[type="button"].formButtonIcon.disabled :hover {
    cursor: initial;
}

#single-right form button[type="button"].formButtonIcon.disabled .buttonIcon:before {
    content: '\f046';
}

.openPrompt {
    color: #176FB7;
    cursor: pointer;
}

.openPrompt:hover {
    color: #111;
}

*, *:focus {
    outline: 0;
}

#labelsOverlay, #gapOverlay, #ubezpieczenieOverlay, #wykupOverlay, #oplataOverlay, #kartaOverlay, #gpsOverlay, #gwarancjaOverlay, #serwisOverlay {
    right: -150%;
    z-index: 999999999;
}

#labelsOverlay .overlay-content, #gapOverlay .overlay-content, #ubezpieczenieOverlay .overlay-content, #wykupOverlay .overlay-content, #oplataOverlay .overlay-content, #kartaOverlay .overlay-content, #gpsOverlay .overlay-content, #gwarancjaOverlay .overlay-content, #serwisOverlay .overlay-content {
    right: 0;
    left: auto;
}

.searchFromButtons {
    margin-bottom: 1rem;
}

.searchButton {
    background: #fff;
    color: #111;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.searchButton:hover {
    background: #B8970F;
    color: #fff;
}



.searchFromButtons flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.searchFromButtons button {
    height: 40px;
    line-height: 38px;
    font-size: .875rem;
    font-weight: normal;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.searchFromButtons button:first-child {
    margin-right: .5rem;
}

.searchFromButtons button:last-child {
    margin-left: .5rem;
}

.searchFromButtons button:after {
    display: none;
}

.searchFromButtons button.inactive {
    background: #fff;
    color: #111;
    font-weight: normal;
}

.searchFromButtons button:hover {
    background: #B8970F;
    color: #fff;
}


.rangeslider--horizontal {
    height: 2px;
    background: #e7e7e7;
    margin: 1.5rem 0 2.5rem;
}

.rangeslider__handle, .rangeslider__handle:focus, .rangeslider__handle:hover, .go-slider .bp3-slider-handle {
    width: 32px;
    height: 32px;
    background: url(assets/images/icons/logo_icon.svg) center center no-repeat;
    background-size: 100% auto;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.rangeslider--horizontal .rangeslider__handle {
    top: -15px;
}

.rangeslider__handle:after {
    display: none;
}

.rangeslider__fill {
    background: #176FB7;
}

.go-slider {
    margin: 10px 0px 40px 0;
}

.go-slider .bp3-slider-track {
    height: 3px;
    top: 14px;
}

#single-right input[type="number"] {
    max-width: 70px;
    margin-bottom: 0;
}

#single-right .form-item > .flex > .flex {
    margin-left: auto;
    justify-content: center;
    align-items: baseline;
}

#single-right .form-item > .flex > .flex span {
    margin-left: 1rem;
}

.line-middle {
    align-self: flex-start;
    font-size: .75rem;
    line-height: 22px;
}

.line-left {
    flex-basis: 140px;
}

.line-left.line-service-card {
    flex-basis: 200px;
}




/* Konfigurator */
#config {
    padding: 100px 0;
}

#config-modal {
    max-width: 660px;
    margin: 0 auto;
    background: #fff;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .4);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .4);
    padding: 1.5rem;
    position: relative;
}

#config #config-modal {
    min-height: 470px;
}

#config-modal .config-modal-body {
    min-height: 290px;
}

#config hr {
    margin: 1rem 0;
}

#config h4 {
    margin-bottom: 0;
    text-transform: none
}

#config h2 {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 0;
}

#config h3 {
    font-size: 1rem;
    text-align: center;
}

#config h4 {
    margin-top: 1rem;
}

#config h4 span {
    color: #B8970F;;
}

#config h1, #config h2, #config h3, #config h4, #config h5 {
    text-transform: none;
}

.kroki {
    background: #f9f9f9;
    justify-content: space-around;
    margin: 1rem 0 2rem;
    font-size: .875rem;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
}

.kroki > * {
    flex-basis: 25%;
    padding: .75rem;
    border-top: 1px solid rgba(0, 0, 0, .15);
}

.kroki > *:nth-child(1) {
    border-left: 1px solid rgba(0, 0, 0, .15);
    border-right: 1px solid rgba(0, 0, 0, .15);
}

.kroki > *:nth-child(2) {
    border-left: none;
    border-right: 1px solid rgba(0, 0, 0, .15);
}

.kroki > *:nth-child(3) {
    border-left: none;
    border-right: 1px solid rgba(0, 0, 0, .15);
}

.kroki > *:nth-child(4) {
    border-left: none;
    border-right: 1px solid rgba(0, 0, 0, .15);
}

.kroki span {
    background: #B8970F;
    color: #fff;
}

.kroki a {
    text-decoration: none;
    color: #111;
    cursor: pointer;
}

.kroki a:hover {
    background: #e7e7e7;
}

.config-modal-footer .flex {
    justify-content: space-between;
}

.config-modal-footer .flex a, .config-modal-footer .flex button {
    width: 140px;
    height: 50px;
    line-height: 50px;
}

.config-modal-footer .flex button.send-proposal {
    width: auto;
    padding: 0 1rem;
}

.config-modal-footer .flex button.endFormButton {
    width: 180px;
}

.config-modal-body form button[type="button"].active {
    background: #176FB7 !important;
}

.config-modal-body form button[type="button"].ms-choice {
    margin-bottom: 0;
}

.config-modal-body form button[type="button"].ms-choice.active {
    background: #f9f9f9 !important;
}

.config-modal-footer {
    margin-top: 2rem;
}

.config-modal-footer .flex button.back, #config .back-to-offer {
    display: inline-block;
    background: #fff;
    color: #111;
    border: 1px solid #e7e7e7;
    border-bottom: 3px solid #e7e7e7;
    text-align: center;
    text-decoration: none;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.config-modal-footer .flex button.back:hover, #config .back-to-offer:hover {
    border-color: #B8970F;
    background: #B8970F;
    color: #fff;
}

.config-modal-footer .flex button.back:after, #config .back-to-offer:after {
    display: none;
}

#config .back-to-offer {
    position: absolute;
    /*top: 20px;*/
    left: 25px;
    font-size: 0.9rem;
    height: 40px;
    line-height: 0px;
}

.btnRequestContactForm {
    position: absolute;
    float:right;
    display: inline-block;
    background: #fff;
    color: #111;
    border: 1px solid #e7e7e7;
    border-bottom: 3px solid #e7e7e7;
    text-align: center;
    text-decoration: none;
    -webkit-border-radius: 3px;
    border-radius: 3px;

    top: 20px;
    left: 25px;
    font-size: 0.9rem;
    height: 40px;
    line-height: 40px;
}

.btnRequestContactForm:hover {
    border-color: #B8970F;
    background: #B8970F;
    color: #fff;
}

.back-to-offer.request {
    float:right;
    position: inherit !important;

}


.kroki span.not-active {
    text-decoration: none;
    color: #111;
    cursor: pointer;
    background: #ffffff;
}

.kroki span.not-active.no-click {
    cursor: default;
}

.kroki span.not-active:hover {
    background: #e7e7e7;
}


.config-modal-body form button[type="button"]:after {
    display: none;
}

.config-modal-body form button[type="button"] {
    padding: 0 1rem;
    margin: 0 1rem 1rem 0;
    -ms-flex-preferred-size: calc(50% - .5rem);
    flex-basis: calc(50% - .5rem);
    background: #176FB7;
}

.config-modal-body form button[type="button"]:not(.active) {
    border: 1px solid #e7e7e7;
    border-bottom: 3px solid #e7e7e7;
    background: #fff;
    color: #111;
}

.config-modal-body form button[type="button"]:nth-child(2n+2) {
    margin: 0 0 1rem 0;
}

.config-modal-body form button[type="button"].fullwidth {
    width: 100%;
    margin-right: 0;
}

.config-modal-body form button[type="button"]:hover {
    background: #f9f9f9;
}

.config-modal-body select{
    width: 100%;
    background: url(assets/images/icons/arrow_circle_bottom.svg) 95% center no-repeat #fff !important;
    background-size: 14px auto !important;
}

.config-modal-body form button[type="button"].gold{
    background: #b8970f;
    color: #fff;
}

#segment-konfig {
    display: inline-block;
    width: 100%;
}

#segment-konfig .column {
    padding: 1rem;
    width: 50%;
    float: left;
}

#segment-konfig .checkbox-label img {
    width: 35px;
    height: 12px;
    margin-right: 10px;
}

.btn-clean {
    height: auto;
    margin: 0;
    padding: 0;
    width: auto;
    border: none !important;
    background: none;
    border-radius: unset;
}

.close-icon-sold-offer {
    cursor: pointer;
    float: right;
}

.btn-clean:hover {
    height: auto;
    margin: 0;
    padding: 0;
    width: auto;
    border: none !important;
    background: none;
    border-radius: unset;
}

.btn-clean:after {
    display: none;
}

.fixed-spinner, .bp3-overlay-scroll-container .bp3-overlay-content .fixed-spinner {
    top: 50%;
    left: 50%;
    position: fixed;
    margin-top: -25px;
    margin-left: -25px;
}

.decision-info {
    color: #176FB7;
    font-weight: bold;
    text-align: center;
}

.decision-section {
    font-size: 16px;
    margin: 1rem;
}

.positive-decision {
    background-color: #eafedc;
}

.neutral-decision {
    background-color: #f2f2f2;
}


/* Multiselect */
/**
 * @author zhixin wen <wenzhixin2010@gmail.com>
 */
.ms-offscreen {
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    outline: 0 !important;
    left: auto !important;
    top: auto !important;
}

.ms-parent {
    width: calc(50% - 10px) !important;
    display: block;
    float: left;
    position: relative;
    margin-bottom: 1rem;
}

.ms-parent:nth-child(3), .ms-parent:nth-child(7) {
    margin-right: 20px;
}

#menu-item-model .ms-parent {
    width: 100% !important;
    margin: 0 0 10px !important;
}

#menu-item-model .ms-drop {
    line-height: 1.5;
}

.ms-choice:hover {
    background: #fff;
}

.ms-choice {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #e7e7e7;
    text-align: left;
    white-space: nowrap;
    line-height: 40px;
    color: #111;
    text-decoration: none;
    border-radius: 3px;
    background-color: #fff;
}

.ms-choice.disabled {
    background-color: #f4f4f4;
    background-image: none;
    border: 1px solid #ddd;
    cursor: default;
}

.ms-choice > span {
    position: absolute;
    top: 0;
    left: 0;
    right: 20px;
    font-size: .875rem;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: block;
    padding-left: 8px;
}

.ms-choice > span.placeholder {
    color: #111;
    font-weight: normal;
    font-size: .875rem;
}

.ms-choice > div.icon-close {
    position: absolute;
    top: 0px;
    right: 16px;
    height: 100%;
    width: 16px;
}

.ms-choice > div.icon-close:before {
    content: '×';
    color: #111;
    position: absolute;
    top: 50%;
    margin-top: -14px;
}

.ms-choice > div.icon-close:hover:before {
    color: #333;
}

.ms-choice > div.icon-caret {
    position: absolute;
    background: url(assets/images/icons/arrow_circle_bottom.svg) center center no-repeat #fff !important;
    background-size: 100% auto !important;
    width: 12px;
    height: 12px;
    top: 16px;
    right: 8px;
    margin-top: -2px;

}

.ms-choice > div.icon-caret.open {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.ms-drop {
    font-size: .875rem;
    width: auto;
    min-width: 220px;
    overflow: hidden;
    display: none;
    margin-top: -1px;
    padding: 0;
    position: absolute;
    z-index: 1000;
    background: #fff;
    color: #000;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.ms-drop.bottom {
    top: 100%;
    -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}

.ms-drop.top {
    bottom: 100%;
    -webkit-box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
}

.ms-search {
    display: inline-block;
    margin: 0;
    min-height: 26px;
    padding: 2px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    z-index: 10000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.ms-search input {
    width: 100%;
    height: 30px !important;
    min-height: 24px;
    padding: 0 5px;
    margin: 0;
    outline: 0;
    font-family: sans-serif;
    border: 1px solid #aaa;
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ms-drop ul {
    overflow: auto;
    margin: 0;
    padding: 0;
}

.ms-drop ul > li {
    text-align: left;
    list-style: none;
    display: list-item;
    background-image: none;
    position: static;
    padding: .25rem 8px;
}

.ms-drop ul > li .disabled {
    font-weight: normal !important;
    opacity: .35;
    filter: Alpha(Opacity=35);
    cursor: default;
}

.ms-drop ul > li.multiple {
    display: block;
    float: left;
}

.ms-drop ul > li.group {
    clear: both;
}

.ms-drop ul > li.multiple label {
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.ms-drop ul > li label {
    position: relative;
    padding-left: 1.25rem;
    margin-bottom: 0;
    font-weight: normal;
    display: block;
    white-space: nowrap;
    cursor: pointer;
}

.ms-drop ul > li label.optgroup {
    font-weight: bold;
}

.ms-drop ul > li.hide-radio {
    padding: 0;
}

.ms-drop ul > li.hide-radio:focus, .ms-drop ul > li.hide-radio:hover {
    background-color: #f8f9fa;
}

.ms-drop ul > li.hide-radio.selected {
    color: #fff;
    background-color: #007bff;
}

.ms-drop ul > li.hide-radio label {
    margin-bottom: 0;
    padding: 5px 8px;
}

.ms-drop ul > li.hide-radio input {
    display: none;
}

.ms-drop ul > li.option-level-1 label {
    padding-left: 28px;
}

.ms-drop input[type="radio"], .ms-drop input[type="checkbox"] {
    position: absolute;
    margin-top: .1rem;
    margin-left: -1.25rem;
}

.ms-drop .ms-no-results {
    display: none;
}

.bp3-menu .checkbox-label {
    min-width: 200px;
    height: 24px;
}

.bp3-transition-container, .bp3-overlay {
    z-index: 1000000000 !important;
}

.bp3-toast-container.bp3-toast-container-top {
    top: 50px !important;
    z-index: 1000000099 !important;
}

.bp3-toast-container {
    align-items: center;
    display: block !important;
    flex-direction: column;
    left: 0;
    overflow: hidden;
    padding: 0 20px 20px;
    pointer-events: none;
    position: fixed;
    right: 0;
    z-index: 40;
}

button.bp3-button, .bp3-toast button.bp3-button {
    border: none !important;
    height: unset;
}

button.bp3-button::after, .bp3-toast button.bp3-button::after {
    display: none;
}

.delete-all-favourite-vehicles-button {
    margin-left: 10px;
    cursor: pointer;
}

.bp3-drawer-header h4 {
    text-transform: unset;
    font-size: 16px;
}

.info-box-content {
    margin: 1rem;
    overflow: auto;
}

/*Contact dialog*/

.dialog .bp3-dialog-header img {
    height: 32px;
    margin-right: 1rem;
}

.dialog .bp3-button-send {
    border: none !important;
    height: 40px;
    text-align: center;
    margin-left: 40%;
    margin-right: 40%;
    text-align: center;
    line-height: 40px;
}

.dialog .bp3-label {
    margin-bottom: 5px;
    margin-left: 15%;
    margin-right: 15%;
    text-align: center;
}

.dialog .bp3-input-group .bp3-input {
    position:relative;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}

.dialog .bp3-dialog-header .bp3-heading {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    flex: 1 1 auto;
    line-height: inherit;
    margin: 0;
    text-align: center;
}

.dialog, .dialog:before, .dialog:after {
    background: #ffffff;
    padding-bottom: 0;
}

.dialog {
    max-width: 500px;
    width: auto;
}

/* Send mail dialog */

.send-mail-dialog .bp3-dialog-header img {
    height: 32px;
    margin-right: 1rem;
}

.send-mail-dialog .bp3-button-send {
    border: none !important;
    height: 40px;
    width: 100px;
    text-align: center;
    margin-left: 40%;
    margin-right: 40%;
    text-align: center;
    line-height: 40px;
}

.send-mail-dialog .bp3-label {
    margin-bottom: 5px;
    margin-left: 15%;
    margin-right: 15%;
    text-align: center;
}

.send-mail-dialog .bp3-input-group .bp3-input {
    position:relative;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}

.send-mail-dialog .bp3-dialog-header .bp3-heading {
    width: 70%;
    position: relative;
    margin-left: 15%;
    margin-right: 15%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    flex: 1 1 auto;
    line-height: inherit;
    margin: 0;
    text-align: center;
}

.send-mail-dialog, .send-mail-dialog:before, .send-mail-dialog:after {
    background: #ffffff;
    padding-bottom: 0;
}

.send-mail-dialog {
    max-width: 500px;
    width: auto;
}

.send-mail-dialog .bp3-dialog-header {
    display: inherit;
    margin-bottom: 20px;
}

.send-mail-dialog .bp3-button.bp3-minimal {
    position: inherit;
    margin-top: 0px;
    z-index: 9999999!important;
    margin-left: 0px;
}

.rodo {
    position:relative;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}

.bp3-dialog-container {
    display: block;
    margin: 30px 0;
    max-width: 500px;
}

.bp3-overlay-inline .bp3-overlay-content, .bp3-overlay-scroll-container .bp3-overlay-content {
    position: relative;
    margin: auto;
}

.bp3-tab-list > *:not(:last-child) {
     margin-right: 0px;
}

.contact-dialog-close-button {
    position:fixed;
    right:10px;
    top:10px;
    z-index:99999999;
}

.contact-dialog-close-button:hover {
    color: #106ba3;
    cursor: pointer;
}

.dialog #contact-form,
.dialog #send-phone-form{
    box-shadow: none;
}

.dialog #contact-form {
    text-align: left;
}

.dialog-close-button {
    align-self: center;
}

.order-dialog {
    width: 660px;
}

.order-dialog h3 {
    margin: 1rem 0 0.5rem;
}

.form-section {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 15px;
}

.form-section .form-group {
    flex-basis: 48%;
    margin-bottom: 5px;
}

.form-section.three-col .form-group {
    flex-basis: 32%;
    margin-bottom: 5px;
}

.form-section .form-group.fill {
    flex-basis: 100%;
}

.header-with-line {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.bp3-tab-indicator-wrapper ~ .bp3-tab{
    padding: 15px;
    padding-bottom: 7px;
}

.bp3-tab[aria-selected="true"] {
    background-color: rgba(191, 191, 191, 0.4) !important;
    box-shadow: inset 0 -3px 0 #106ba3;
}

.app-toaster a {
    color: #176FB7 !important;
}

#contact-form .bp3-datepicker-caption select, #config-modal .order-form .bp3-datepicker-caption select,
#send-phone-form .bp3-datepicker-caption select, #config-modal .order-form .bp3-datepicker-caption select{
    height: unset;
    border: none !important;
    background: none !important;
    line-height: unset;
    margin: 0;
}

#contact-form .bp3-datepicker-navbar button, #config-modal .order-form .bp3-datepicker-navbar button,
#send-phone-form .bp3-datepicker-navbar button, #config-modal .order-form .bp3-datepicker-navbar button{
    width: auto;
    flex-basis: unset;
    margin: 0;
    padding: 0;
}

#config-modal .order-form .bp3-datepicker-navbar > .DayPicker-NavButton--prev {
    margin-right: auto;
}


#config-modal .order-form .bp3-datepicker-navbar > .DayPicker-NavButton--next {
    margin-left: auto;
}

.bp3-input {
    line-height: 40px;
    padding: 0 0 0 .5rem;
    margin: 0 0 1rem;
    color: #111;
    font-size: 0.875rem;
    font-weight: 400;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: none;
}

.bp3-numeric-input .bp3-input-group{
    width: 100%;
}

#contact-form span.bp3-popover-target, #config-modal .order-form span.bp3-popover-target,
#send-phone-form span.bp3-popover-target, #config-modal .order-form span.bp3-popover-target{
    width: 100%;
}

.bp3-input:focus, .bp3-input.bp3-active {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .2);
    border: 1px solid #ccc;
}

.representative-title {
    flex-basis: 100%;
    margin-bottom: 1rem;
}

#contact-form .add-representative-button, .order-form .add-representative-button,
#send-phone-form .add-representative-button, .order-form .add-representative-button{
    width: auto;
    padding: 0 1rem;
}

#contact-form .remove-representative-button, .order-form .remove-representative-button,
#send-phone-form .remove-representative-button, .order-form .remove-representative-button{
    float: right;
    width: auto;
    height: 30px;
    line-height: normal;
    padding: .3rem .5rem !important;
}

.dialog-btn {
    width: auto;
    padding: 0 1rem;
    margin: 0 .5rem;
    height: 40px;
    line-height: 40px;
    float: right;
}

.reverse-btn {
    background-color: #ffffff;
    color: #000000;
}

.reverse-btn:after {
    display: none;
}

.reverse-btn:hover {
    color: #ffffff;
}

.input-error {
    color: #db3737;
    font-size: .75rem;
}

.grid-view .offer-img {
    height: 10rem;
}

.grid-view .offer-img, #hot-offers-bg, #similiar-offers-bg .offer-img {
    border-top: 1px solid #bebebe;
    border-bottom: 1px solid #bebebe;
}

.bp3-toast.bp3-intent-warning {
    background-color: #B8970F !important;
}

.social-button-wrapper {
    margin-top: 0.32rem;
    margin-right: 2px;
    margin-left: 2px;
    width: 16px;
    height: 16px;
    background-color: rgb(23, 111, 183);
    border-radius: 2px;
}

.social-button {
    padding: 2px;
    color: white;
    margin-bottom: 3px;
}

.social-button-svg {
    padding: 2px;
    margin-bottom: 9px;
}

.social-button-svg-wrapper {
    margin-top: 0.32rem;
    margin-right: 2px;
    margin-left: 2px;
    width: 16px;
    height: 16px;
    background-color: rgb(23, 111, 183);
    border-radius: 2px;
}

.social-button-svg-wrapper:hover {
    cursor: pointer;
    background-color: rgb(23, 111, 183, 0.75);
}

.social-share-button {
    padding: 5px;
    padding-bottom: 0px;
    display: flex;
    margin-right: -15px;
}

.react-share__ShareButton {
    border-bottom: 0px !important;
}

.social-button-wrapper:hover {
    cursor: pointer;
    background-color: rgb(23, 111, 183, 0.75);
}

.social-button-popover {
    display: inline-flex;
}

.social-button-popover:first-child {
    margin-left: 10px;
}

.social-popover {
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.4);
}

p.first{
    margin-top: 0;
}

p.last{
    margin-bottom: 0;
}

.bp3-tab-list {
    margin-top: 1.5rem;
    margin-bottom: 1.75rem;
    display: flex;
    justify-content: space-evenly;
}

.phone-call {
    display: flex;
    color:black;
    font-size: 2rem;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 1.5rem;
    font-weight: bold;
    transition: 0.3s;
}

.phone-call:hover {
    text-decoration: underline;
    cursor: pointer;
    color: #176FB7;
}

.phone-input {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 1.5rem;
}

.contact-tabs {
    display: inline-block;
    align-content: center;
    justify-content: space-evenly;
}

.phone-form {
    display: -ms-inline-flexbox;
    justify-content: center;
    text-align: center;
    margin-left: 0%;
}

.mobile-phone-call-wrapper {
    display: flex;
    position: fixed;
    top: 120px;
    right: 20px;
    width: 50px;
    height: 50px;
    z-index: 999999999;
    border-radius: 45px !important;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
}

.mobile-favourite-call-wrapper {
    display: flex;
    position: fixed;
    top: 180px;
    right: 20px;
    width: 50px;
    height: 50px;
    z-index: 999999999;
    border-radius: 45px !important;
}

.dialog .bp3-dialog-header {
    box-shadow: 0 0;
}

.dialog .bp3-tab-list {
    text-align: center;
    align-content: space-around;
    display: inline-flex;
    margin: 0 auto;
    margin-bottom: -10px;
}

.bp3-tab-list > div {
    width: 76%;
}

.bp3-dialog-header {
    display: none;
}

.send-mail-dialog {

}

.contact-tabs .bp3-tab:nth-child(2){
    border-top-left-radius: 6px;
}

.contact-tabs .bp3-tab:nth-child(3) {
    border-top-right-radius: 6px;
}

.bp3-button.bp3-minimal {
    position: absolute;
    right: 10px;
    z-index: 9999999!important;
}

.bp3-tabs.contact-tabs {
    display: inline-block;
    text-align: center;
}

/*Things that should be only printed, not displayed*/

#seller-print-contact {
    display: none;
}

#print-logo {
    display: none;
}

.order-form .bp3-input-action{
    margin-top: 5px;
}

/* Labele i ribbony */

.offer-img div.ribbon-hot-text-grid-view {
    text-align: center;
    font-weight: 900;
    font-size: 13px;
    color: yellow;
    background-color: black;
    border-radius: 4px;
    width: 140px;
    height: 27px;
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
}

.ribbon-hot-text-list-view {
    text-align: center;
    font-weight: 900;
    font-size: 15px;
    color: yellow;
    background-color: black;
    border-radius: 4px;
    width: 175px;
    height: 34px;
    margin-bottom: 20px;
    padding: 7px;
    position: relative;
    box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
}


.offer-img div.ribbon-rental-grid-view,.offer-img div.ribbon-credit-grid-view {
    text-align: center;
    font-weight: 900;
    font-size: 13px;
    color: black;
    background-color: yellow;
    width: 140px;
    height: 27px;
    padding: 5px;
    position: absolute;
    bottom: 10px;
    right: -5px;
    box-shadow: 1px 5px 5px rgba(68, 68, 68, 0.6);
}



.ribbon-rental-list-view, .ribbon-credit-list-view {
    text-align: center;
    font-weight: 900;
    font-size: 15px;
    color: black;
    background-color: yellow;
    border-radius: 4px;
    width: 175px;
    height: 34px;
    margin-bottom: 20px;
    padding: 7px;
    position: relative;
    box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
}


.ribbon-od-reki {
    position: absolute;
    top: 0px;
    left: 0px;
    background: url(assets/images/od-reki-ribbon.svg) center center no-repeat;
    background-size: 100% auto;
    width: 150px;
    height: 150px;
    margin-top: -67px;
    margin-left: -6px;
}

.ribbon-od-reki-grid {
    position: absolute;
    top: 0px;
    left: 0px;
    background: url(assets/images/od-reki-ribbon.svg) center center no-repeat;
    background-size: 100% auto;
    width: 90px;
    height: 90px;
    margin-top: -4px;
    margin-left: -4px;
}

.ribbon-hot-text {
    text-align: center;
    font-weight: 900;
    color: yellow;
    background-color: black;
    border-radius: 4px;
    width: 185px;
    height: 27px;
    padding: 2px;
    position: absolute;
    top: 40px;
    right: 34px;
    transition: all 0.15s ease-in-out;
    box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
    z-index: 999;
}

.ribbon-rental-offer-view, .ribbon-credit-offer-view {
    text-align: center;
    font-weight: 900;
    color: black;
    background-color: yellow;
    width: 185px;
    height: 27px;
    padding: 2px;
    position: absolute;
    bottom: 40px;
    right: -5px;
    transition: all 0.15s ease-in-out;
    box-shadow: 1px 5px 5px rgba(68, 68, 68, 0.6);
    z-index: 999;
}


.offer-img div.ribbon-credit-grid-view, div.ribbon-credit-list-view, .ribbon-credit-offer-view {

    color: #ffffff;
    background-color: #8e8e8e;
}

.single-cover .gwarancja-zwrotu img {
    max-width: 20px;
    margin-right: .3rem;
}

.single-cover .gwarancja-zwrotu {
    position: absolute;
    top: 100px;
    right: -3px;
    z-index: 999;
    max-height: 30px;
    height: 28px;
    color: white;
    background: red;
    padding: 2px;
    border-radius: 4px;

}

.ribbon-hot-text-active {
    transform: scale(1.25);
}

.ribbon-hot-text:hover {
    transform: scale(1.25);
}

.process-step{
    flex-basis: 20%;
    margin: 3rem 0;
    display: flex;
    justify-content: center;
    background: url(assets/images/icons/arrow_circle_right_grey.svg) 100% 25% no-repeat;
    background-size: 22px;
}


.process-step:last-child{
    background: none;
}


.process-step img{
    height: 65px;
}

.process-step .process-name{
    margin-top: 25px;
    font-size: 18px;
}

.our-strengths{
    flex-basis: 50%;
    color: #ffffff;
    padding-left: 70px;
}

.our-strengths-vans{
    flex-basis: 50%;
    color: 	#000000;
    padding-left: 70px;
}

.choose-counter{
    font-size: 3.5rem;
    line-height: 4rem;
}

.strengths-list{
    font-size: 1.5rem;
    margin-top: 60px;
}

.strengths-list li{
    margin-bottom: 30px;
    padding-left: 60px;
    position: relative;
}

.strengths-list li:before {
    content: '';
    background: url("assets/images/icons/logo_icon_gold.svg") center center no-repeat;
    width: 2rem;
    height: 2rem;
    position: absolute;
    left: 0;
    top: 6%;
    background-size: cover;
}

.strengths-list li strong{
    font-weight: 800;
}

.bold{
    font-weight: bold;
}

.ask-for-price{
    cursor: pointer;
    text-decoration-line: underline;
    text-decoration-style: dashed;
}

.wartosc-katalogowa span.bp3-icon-share{
    color: grey;
}

.before-form .full-price span.basic-installment{
    color: #000000;
    text-decoration-line: line-through;
    font-size: 1rem;
    text-align: right;
    margin-right: 10px;
    justify-self: end;
}

.before-form .full-price span.installment-discount{
    color: #000000;
    font-size: 1.2rem;
    grid-column: 3;
    margin-top: 5px;
}

.before-form .full-price span.special-offer{
    background-color: yellow;
}


.grid{
    display: grid;
}

.full-price.grid{
    grid-template-columns: 28% 28% 44%;
    grid-template-rows: 50% 20% 30%;
    align-items: center;
    justify-items: start;
}

.vehicle-gallery{
    max-height: 534px;
    height: 60vw;
}


.vehicle-gallery ._1_Dg2{
    z-index: 1000000000;
}

.vehicle-gallery button:after{
    display: none;
}
.vehicle-gallery button, .vehicle-gallery button:hover{
    border: 0 !important;
    background-color: transparent;
}



#content .vehicle-gallery ul{
    padding: 0;
    margin: 0;
}

.vehicle-gallery ._2c50p{
    margin-bottom: 3px;
}

.single-offer-image-container{
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.vehicle-gallery .single-offer-image-container img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.bp3-overlay-inline .bp3-overlay-content.calculator-spinner{
    position: absolute;
    top: 25%;
    left: 45%;
}

/*******************
 *******************

        RWD

 *******************
 *******************/

@media all and (min-width: 1200px) {

    /* Layout */
    .container, .container.fullwidth {
        width: 1180px;
    }

    /* Product menu */
    #mobile-menu-trigger-wrapper, .mobileMainMenuClose, .mobileProductMenuClose {
        display: none;
    }
}

@media all and (min-width: 1366px) {

    /* Layout */
    .container {
        width: 1280px;
    }

    .container.fullwidth {
        width: 90%;
    }

}

@media all and (min-width: 1600px) and (max-width: 1919px) {

    /* Home */
    #home-intro {
        padding: 100px 0 50px;
    }

    #search-form {
        margin-bottom: 40px;
    }

    #home-intro-slider span {
        font-size: 0.875rem;
    }

    #home-intro-slider .slide {
        padding: 0;
    }

}

@media all and (min-width: 1366px) and (max-width: 1599px) {



    /* Layout */
    .container.fullwidth {
        width: 1280px;
    }

    /* Header */
    #top-contact .hours {
        font-size: .75rem;
    }

    /* Menu */
    #menu-item-marki .product-sub-menu {
        width: 350%;
    }

    #menu-item-segment .product-sub-menu {
        width: 225%;
    }

    /* Home */
    #home-intro {
        padding: 100px 0 50px;
    }

    #search-form {
        margin-bottom: 40px;
    }

    #search-form form {
        margin-left: 0;
    }

    #home-intro-slider span {
        font-size: 0.875rem;
    }

    #home-intro-slider .slide {
        padding: 0;
    }

}


@media all and (min-width: 1200px) and (max-width: 1365px) {

    .list-view  #favourite-button-offer-page img{
        height: 32px;
        width: 32px;
        padding-bottom: 13px;
    }

    /* Layout */
    .container, .container.fullwidth {
        width: 90%;
    }

    /* Header */
    #logo img {
        width: 130px;
    }

    #menuToggle {
        font-size: .875rem;
    }

    #menuToggle img {
        max-height: 13px;
    }

    #top-contact .hours {
        display: none;
    }

    #top-phone a {
        font-size: 1.25rem;
    }

    /* Header toggle */
    .can-toggle label .can-toggle__switch:before {
        left: 68px;
        font-size: 0.625rem;
        width: 70px;
        padding: 0 12px;
    }

    .can-toggle label .can-toggle__switch:after {
        top: 0px;
        left: 0px;
        border-radius: 2px;
        width: 68px;
        font-size: 0.625rem;
    }

    .can-toggle label .can-toggle__switch {
        -ms-flex: 0 0 140px;
        -webkit-box-flex: 0;
        flex: 0 0 140px;
        border-radius: 4px;
    }

    .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
        -webkit-transform: translate3d(68px, 0, 0);
        transform: translate3d(68px, 0, 0);
    }

    /* Menu */
    #product-menu-wrapper {
        padding: 0 1rem;
    }

    #menu-item-marki .product-sub-menu {
        width: 375%;
    }

    #menu-item-segment .product-sub-menu {
        width: 250%;
    }


    /* Home */
    #search-form form {
        margin-left: 0;
        padding: 1rem;
    }

    #home-intro-slider span, #home-intro-slider strong {
        font-size: .75rem;
    }

    #home-intro {
        padding: 100px 0 50px;
    }

    #search-form {
        margin-bottom: 40px;
    }

    #search-form form {
        margin-left: 0;
    }

    #home-intro-slider .slide {
        padding: 0;
    }


    /* Pages */
    .contact-data img.contact-logo {
        max-width: 150px;
    }

    #contact-content, #contact-form, #send-phone-form {
        -ms-flex-preferred-size: 47%;
        flex-basis: 47%;
    }

    #content .container, .side-padding {
        padding: 0 1rem;
    }

    /* Oferta */
    #form-submit {
        margin-left: 0;
        left: auto;
        right: calc(5% + 1rem);
        max-width: 30%;
    }

}

@media all and (min-width: 1024px) and (max-width: 1199px) {

    .grid-view #favourite-button-offer-page img{
        flex-basis: auto;
        height: 40px;
        width: 20px;
        padding-bottom: 5px;
        min-width: 20px;
    }

    .list-view  #favourite-button-offer-page img{
        height: 30px;
        padding-bottom: 8px;
    }

    /* Blog */
    .blogItem {
        flex-basis: 45%;
        margin-bottom: 2rem;
    }

    .blogPosts .blogItem {
        flex-basis: 30%;
    }


    /* Layout */
    .container, .container.fullwidth {
        width: 90%;
    }

    body, .faq-question, input, button, select {
        font-size: .875rem;
    }

    .onlymobile {
        display: none;
    }


    /* Header */
    header, #menuToggle {
        font-size: .75rem;
    }

    #top-contact .hours {
        display: none;
    }

    #top-phone a {
        font-size: 1rem;
    }

    #logo img {
        width: 130px;
    }

    .top-icon img {
        max-height: 20px;
    }

    #menuToggle img {
        max-height: 12px;
    }

    #top-mail img {
        height: 24px;
    }

    #favourite-button img {
        height: 24px;
    }

    #top-phone img {
        max-height: 18px;
    }

    /* Menu */
    #product-menu-wrapper {
        padding: 0;
    }

    #menu-item-marki .product-sub-menu {
        width: 375%;
    }

    #menu-item-segment .product-sub-menu {
        width: 250%;
    }

    /* Header toggle */
    .can-toggle label .can-toggle__switch:before {
        left: 68px;
        font-size: 0.625rem;
        width: 70px;
        padding: 0 12px;
    }

    .can-toggle label .can-toggle__switch:after {
        top: 0px;
        left: 0px;
        border-radius: 2px;
        width: 68px;
        font-size: 0.625rem;
    }

    .can-toggle label .can-toggle__switch {
        -ms-flex: 0 0 140px;
        -webkit-box-flex: 0;
        flex: 0 0 140px;
        border-radius: 5px;
    }

    .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
        -webkit-transform: translate3d(68px, 0, 0);
        transform: translate3d(68px, 0, 0);
    }

    /* Home */
    #search-form form {
        margin-left: 0;
        padding: 1rem;
    }

    #home-intro-slider span, #home-intro-slider strong {
        font-size: .75rem;
    }

    #home-intro {
        padding: 100px 0 50px;
    }

    #search-form {
        margin-bottom: 40px;
    }

    #home-intro-slider span {
        font-size: 0.875rem;
    }

    #home-intro-slider .slide {
        padding: 0;
    }

    /* Product menu */
    #mobile-menu-trigger-wrapper, .mobileMainMenuClose, .mobileProductMenuClose {
        display: none;
    }


    /* Pages */
    .contact-data img.contact-logo {
        max-width: 130px;
    }

    #contact-content, #contact-form, #send-phone-form {
        -ms-flex-preferred-size: 48%;
        flex-basis: 48%;
    }

    #content .container, .side-padding {
        padding: 0;
    }


    /* Lista ofert */
    #offers-list.list-view .offer .offer-top, #offers-list.list-view .offer-list-view, #offers-list.list-view .offer .offer-bottom {
        padding: 1rem;
    }

    #offers-list.list-view .offer .offer-top {
        -ms-flex-preferred-size: 40%;
        flex-basis: 40%;
    }

    #offers-list.list-view .offer-list-view {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    }

    #offers-list.list-view .offer .offer-bottom {
        -ms-flex-preferred-size: 10%;
        flex-basis: 10%;
    }

    #offers-list.list-view .offer-list-view .ribbon {
        font-size: .75rem;
    }

    #offers-list.list-view .offer-top .offer-title, #offers-list.grid-view .offer-top .offer-title {
        font-size: .875rem;
    }

    #offers-list .offer .offer-top .offer-text {
        font-size: .75rem;
    }

    #offers-list.list-view .full-price {
        font-size: 1rem;
        text-align: right;
    }

    #offers-list.list-view .offer-btn {
        height: 34px;
        line-height: 34px;
    }

    #offers-list.list-view .offer .offer-img {
        -ms-flex-preferred-size: 17.5%;
        flex-basis: 17.5%;
    }


    /* Pojedyncza oferta */
    #single-left {
        -ms-flex-preferred-size: 55%;
        flex-basis: 55%;
        max-width: 55%
    }

    #single-right {
        -ms-flex-preferred-size: 40%;
        flex-basis: 40%;
    }

    #single-right {
        padding: 1rem;
    }

    #single-right form button[type="button"] {
        font-size: .75rem;
        font-weight: normal;
        height: 40px;
        line-height: 40px;
    }

    .fixed-box {
        width: 35%;
    }

    .fixed-box button#send-form {
        margin-top: auto;
    }

    .oferta #gotop {
        bottom: 170px;
    }

    #form-submit {
        margin-left: 0;
        left: auto;
        right: 5%;
        max-width: 36%;
    }

    .single-box > .offerBoxContent > .flex {
        flex-wrap: wrap;
    }

    .column {
        margin: 0 5% 1rem 0;
        flex-basis: 45%;
    }


    /* Fixy */
    .top-search {
        width: 250px;
    }

    #home-text-content h3 {
        text-align: left;
    }

    #quotes .flex {
        flex-wrap: wrap;
    }

    .quote-item {
        flex-basis: 47%;
        margin-bottom: 1rem;
    }

    #newsletter form button {
        height: 100%;
        line-height: 1;
        align-self: center;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
    }

    #newsletter form input {
        align-self: center;
    }

    .ribbon-hot-text {
        width: 155px;
        height: 25px;
        padding: 2px;
        position: absolute;
        top: 40px;
        right: 18px;
    }

}

@media all and (max-width: 1023px) {

    /* Blog */
    .blogItem {
        flex-basis: 47%;
        margin-bottom: 2rem;
    }

    .blogFeatured .blogCover {
        flex-basis: 50%;
    }

    .blogFeatured .blogShort {
        flex-basis: calc(50% - 40px)
    }

    /* Layout */
    .container {
        width: 90%;
    }

    .hiddenBody {
        overflow-y: scroll;
        position: relative;
        height: 100%;
    }

    body, .faq-question, input, button, select {
        font-size: .875rem;
    }

    #content .container, .side-padding {
        padding: 0;
    }

    .onlymobile {
        display: none;
    }


    /* Header */
    .top-icon, .hours {
        display: none;
    }

    #top-phone a {
        font-size: 1rem;
    }

    #logo img {
        width: 120px;
    }

    header, #menuToggle {
        font-size: .75rem;
    }

    #menuToggle img {
        max-height: 12px;
    }

    #top-mail img {
        height: 22px;
    }

    #favourite-button img {
        height: 22px;
    }

    #top-phone img {
        max-height: 18px;
    }

    /* Header toggle */
    #switch {
        width: 140px;
    }

    .can-toggle label .can-toggle__switch:before {
        left: 68px;
        font-size: 0.625rem;
        width: 70px;
        padding: 0 12px;
    }

    .can-toggle label .can-toggle__switch:after {
        top: 0px;
        left: 0px;
        border-radius: 2px;
        width: 68px;
        font-size: 0.625rem;
    }

    .can-toggle label .can-toggle__switch {
        -ms-flex: 0 0 140px;
        -webkit-box-flex: 0;
        flex: 0 0 140px;
        border-radius: 2px;
    }

    .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
        -webkit-transform: translate3d(68px, 0, 0);
        transform: translate3d(68px, 0, 0);
    }

    /* Home */
    #search-form form {
        margin: 0 auto;
    }

    #home-intro {
        padding: 100px 0;
        animation: inherit;
    }

    #home-intro-slider .slide {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    #home-intro-slider .owl-prev {
        background: url(assets/images/icons/arrow_left.svg) no-repeat;
        background-size: 12px auto;
        background-position: center center;
        margin-top: -18px;
    }

    #home-intro-slider .owl-next {
        background: url(assets/images/icons/arrow_right.svg) no-repeat;
        background-size: 12px auto;
        background-position: center center;
        margin-top: -18px;
    }

    #home-icons .flex, #usp .flex, #quotes .flex, #marki .flex, #others .flex {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .home-icon, .usp-item, .quote-item {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        margin-bottom: 3rem;
    }

    #home-icons, #usp, #quotes, #others {
        padding-bottom: 0;
    }

    #home-text .flex {
        display: block;
    }

    #home-text-content {
        margin-bottom: 2rem;
    }

    #home-text iframe {
        display: block;
        margin: 0 auto;
    }

    #marki a {
        -ms-flex-preferred-size: 16.666%;
        flex-basis: 16.666%;
        text-align: center;
    }

    #marki a img {
        display: block;
        margin: 0 auto;
    }

    .marki-line:first-child {
        margin-bottom: 0;
    }

    .other {
        -ms-flex-preferred-size: 48%;
        flex-basis: 48%;
        margin-bottom: 3rem;
    }

    #others .flex {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    #newsletter .container.flex {
        display: block;
        text-align: center;
    }

    #newsletter h4 {
        margin-bottom: 2rem;
    }

    /* Footer */
    footer .container {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .footer-column {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        margin-bottom: 3rem;
    }

    footer .container.flex {
        padding-bottom: 1rem;
    }

    /* Page */
    #page-intro {
        min-height: 100px;
        padding: 60px 0;
    }

    .page-intro-text br {
        display: none;
    }

    .page-intro-text, .page-intro-text p {
        font-size: 0.8rem;
    }

    .kontakt .container.flex {
        display: block;
    }

    /* Lista ofert */
    #offers-list.grid-view .offer, #offers-list.grid-view .version-popover {
        -ms-flex-preferred-size: 48%;
        flex-basis: 48%;
    }

    .grid-view .offer-img{
        height: 17rem;
    }

    #offers-list.list-view .offer .offer-top {
        padding: 1rem;
    }

    #offers-list.grid-view .offer:nth-child(4n+4), #offers-list.grid-view .offer:last-child, #offers-list.grid-view .version-popover:nth-child(4n+4), #offers-list.grid-view .version-popover:last-child {
        margin: 0 0 1.5rem;
    }

    #offers-list.list-view .offer {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        border-radius: 0;
    }

    #offers-list.list-view .offer .offer-img {
        -ms-flex-preferred-size: 15%;
        flex-basis: 15%;
        padding: 1%;
        -ms-flex-item-align: start;
        align-self: flex-start;
    }

    #offers-list.list-view .offer .offer-img img {
        border-radius: 0;
    }

    #offers-list.list-view .offer .offer-top {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        padding: 1%;
    }

    #offers-list.list-view .offer-list-view {
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        padding: 1%;
    }

    #offers-list.list-view .offer .offer-bottom {
        -ms-flex-preferred-size: 10%;
        flex-basis: 10%;
        padding: 1%;
    }

    #offers-list.list-view .offer-list-view {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    #offers-list.list-view .offer-list-view .ribbon {
        font-size: 0.625rem;
    }

    #offers-list.list-view .offer-btn, #offers-list.list-view .offer-list-view .ribbon {
        height: 28px;
        line-height: 28px;
    }

    #offers-list .offer-top .offer-title {
        font-size: .875rem;
    }

    #offers-list .offer .offer-top .offer-text, #offers-list.list-view .offer-top .offer-title {
        font-size: .75rem;
    }


    #offers-list.list-view .offer-top .offer-title {
        font-size: .8rem;
    }

    #offers-list.list-view .offer .full-price span {
        font-size: .95rem;
        text-align: right;
    }

    #offers-list.list-view .offer-list-view .ribbon.hot-label, #offers-list.list-view .offer-list-view .ribbon.od-reki-label {
        padding: .2rem;
        height: 20px;
        line-height: 1.2;
        text-align: center;
        width: 100%;
        display: block;
        font-size: 0.7rem;
        margin: 0 0 .1rem;
    }

    .ribbon-hot-text-list-view {
        padding: .2rem;
        height: 18px;
        line-height: 1.2;
        text-align: center;
        width: 100%;
        display: block;
        margin-bottom: 10px !important;
        font-size: 0.55rem;
        margin: 0 0 .1rem;
    }

    #offers-list.list-view .offer-btn {
        padding: 0 .5rem;
    }

    #offers-list.list-view .offer-btn::after {
        width: 8px;
        height: 8px;
        margin-left: 5px;
    }

    /* Pojedyncza oferta */
    .single-heading-1 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

    }

    .single-heading-info {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        margin-left: 0;
        text-align: left;
        margin-top: 1rem;
    }

    #single-left, #single-right {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }

    #content > .container.flex {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .fixed-box {
        width: 100%;
    }

    .fixed-box-right {
        -ms-flex-preferred-size: 45%;
        flex-basis: 45%
    }

    .single-box .offerBoxContent > .flex, .tab-content > .flex {
        display: block;
    }

    .column {
        margin-right: 0;
    }

    .oferta #gotop {
        bottom: 170px;
    }

    #form-submit {
        margin-left: 0;
        left: auto;
        right: 5%;
        width: 100%;
        max-width: auto;
    }

    #form-submit.staticSubmit {
        width: calc(100% + 2rem)
    }


    /* Fixy */
    .top-search {
        display: none;
    }

    .home_banner, .usp-item, .quote-item {
        flex-basis: 47%;
        margin-bottom: 1.5rem;
    }

    .ribbon-hot-text {
        width: 155px;
        height: 25px;
        padding: 2px;
        position: absolute;
        top: 40px;
        right: 18px;
    }

    .our-strengths-vans{
        flex-basis: 50%;
        color: #ffffff;
        padding-left: 70px;
    }

}
@media all and (min-width: 900px) and (max-width: 1023px) {
    .grid-view #favourite-button-offer-page {
        display: inline-block;
        position: absolute;
        top: 88%;
        right: 8rem;
        padding-top: 5px
    }
}

@media all and (min-width: 768px) and (max-width: 1023px) {

    .grid-view #favourite-button-offer-page {
        display: inline-block;
        position: absolute;
        top: 88%;
        right: 8rem;
        padding-bottom: 10px;
    }

    .list-view  #favourite-button-offer-page{
        display: inline-block;
        position: absolute;
        top: -3%;
        right: 0.5rem;
        padding-bottom: 10px;
    }

    .list-view  #favourite-button-offer-page img{
        height: 32px;
        padding-bottom: 13px;
    }

    /* Product menu tablet */
    #product-menu-wrapper {
        padding: 0;
    }

    #menu-item-marki .product-sub-menu {
        width: 450%;
    }

    #menu-item-segment .product-sub-menu {
        width: 300%;
    }

    .product-menu-item, .product-sub-menu {
        font-size: .75rem;
    }

    .product-sub-menu input, .product-sub-menu select, .product-sub-menu button {
        font-size: .75rem;
        height: 40px;
        line-height: 40px;
    }

    /* Product menu */
    #mobile-menu-trigger-wrapper, .mobileMainMenuClose, .mobileProductMenuClose {
        display: none;
    }

    /* Pojedyncza oferta */
    .th-cena, .td-cena {
        display: none;
    }

}

@media all and (min-width: 670px ) and (max-width: 767px) {

    .grid-view #favourite-button-offer-page {
        display: inline-block;
        position: absolute;
        top: 87%;
        right: 8rem;
        padding-bottom: 10px;
    }
}

@media all and (max-width: 767px) {

    .list-view  #favourite-button-offer-page{
        display: inline-block;
        position: absolute;
        top: -3%;
        right: 0.5rem;
        padding-bottom: 10px;
    }

    .list-view  #favourite-button-offer-page img{
        height: 32px;
        padding-bottom: 13px;
    }

    .grid-view .offer-img {
        height: 13rem;
    }

    #favourite-button-head-section{
        display: inline-block;
        margin-left: 5px;
        margin-right: -5px;
        padding-bottom: 0px;
    }

    #favourite-button-head-section img{
        max-height: 30px;
        padding-bottom: 8px;
    }

    #sorting-bar.fixedSortingBar{
        z-index: 9999999;
    }

    /* Blog */
    .blogFeatured .blogCover {
        flex-basis: 100%;
        margin-bottom: 1rem;
    }

    .blogFeatured .blogShort {
        flex-basis: 100%;
    }

    .blogPostsWrapper .blogFeatured {
        margin-bottom: 2rem;
    }

    .singleIntro {
        margin-bottom: 0;
    }

    .singleIntro > div {
        flex-basis: 100% !important;
    }

    .singleIntro .singleCover {
        margin-bottom: 1rem;
    }

    .metaTags {
        margin-top: .5rem;
    }

    .post #content {
        margin-top: 1rem;
    }

    .singleBottom > * {
        flex-basis: 100% !important;
    }

    .printThis {
        text-align: center;
        margin-bottom: 0.5rem;
    }

    .shareThis span {
        display: block;
        text-align: center;
        margin-bottom: .5rem;
    }

    .bp3-menu {
        min-width: 110px;
    }


    /* Header */
    header .container {
        height: 80px;
    }

    .single-heading-1 > h1{
        font-size: 22px;
    }

    #favourite-button-head-section{
        display: inline-block;
        margin-left: -5px;
    }

    #top-contact #favourite-button {
        display: block;
        z-index: 9999999;
    }

    #top-contact:not(#favourite-button), #menuToggle {
        display: none;
    }

    #favourite-button-offer-page img{
        height: 30px;
        width: 30px;
        padding-bottom: 7px;
    }

    /* Home */
    iframe {
        max-width: 100%;
    }

    /* Mobile menu */
    .product-sub-menu button {
        max-width: 100%;
    }

    #product-menu {
        display: none;
    }

    #mobile-menu-trigger-wrapper {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        color: #fff;
        z-index: 9999;
    }

    #mobile-menu-trigger-wrapper > div {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    }

    #mobile-menu-trigger-wrapper div.mobileMainMenuToggle {
        cursor: pointer;
        padding: 1rem .5rem;
        color: #fff;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background: #333;
        max-height: 50px;
    }

    #mobile-menu-trigger-wrapper div.mobileMainMenuToggle span {
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    }

    #mobile-menu-trigger-wrapper div.mobileMainMenuToggle:hover {
        background: #111;
    }

    #mobile-menu-trigger-wrapper div.mobileMainMenuToggle img {
        margin-right: 10px;
        max-width: 16px;
    }

    .mobileMainMenuClose, .mobileProductMenuClose {
        float: right;
        margin-bottom: 1rem;
    }

    #main-menu .menu, #product-menu-wrapper {
        clear: both;
    }

    #main-menu .menu {
        text-align: center;
    }

    #main-menu, #product-menu {
        position: fixed;
        width: 100%;
        padding: 1rem;
        height: 100vh;
        top: 0;
        left: 0;
        overflow: hidden;
        overflow-y: scroll;
        font-size: 1rem;
        z-index: 99999999;
    }

    #product-menu {
        background: #fff;
        overflow-y: scroll;
        padding-bottom: 4rem;
    }

    #product-menu-wrapper {
        padding: 0;
        display: block;
    }

    .product-menu-item {
        font-size: 1rem;
        position: static;
    }

    .product-menu-item, .product-menu-item:last-child {
        border: none;
        display: block;
        height: auto;
        width: 100%;
    }

    .product-sub-menu {
        width: 100% !important;
        display: none;
        position: static;
        visibility: visible;
        opacity: 1;
        overflow: hidden;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: none;
        overflow: visible;
    }

    .product-menu-item.active .product-sub-menu {
        display: block
    }

    .sub-menu-column {
        width: 100% !important;
        float: none !important;
        clear: both !important;
        padding: 0 !important;
    }

    .product-sub-menu .clearfix {
        text-align: left;
        font-size: 1rem;
        padding: 1rem 0;
    }

    .onlymobile {
        display: block;
    }

    /* Lista ofert */
    #sorting-bar {
        padding: .5rem 0;
    }

    #sorting-bar .btn {
        height: 40px;
        line-height: 40px;
    }

    #view-toggle-wrapper {
        margin-top: .5rem;
    }

    .lista-ofert #content {
        margin-top: 1rem;
    }

    div.lista-ofert #page-intro{
        padding: 10px 0;
        min-height: unset;
    }

    .lista-ofert #page-intro .breadcrumb{
        display: none;
    }

    #fiter-toggle.btn, #sorting-toggle.btn {
        padding: 0 1rem;
    }



    #offers-list .offer .offer-top .offer-text, #offers-list.list-view .offer .offer-top .offer-points, #offers-list.list-view .offer .full-price small {
        font-size: .5625rem;
    }



    /* Pojedyncza oferta */
    .th-cena, .td-cena {
        display: none;
    }

    #single-intro {
        padding: 1rem 0;
    }


    .offerBoxContent {
        padding-top: 1rem;
    }

    .openBox {
        width: auto;
        padding: 0 1rem;
    }

    #single-tabs {
        width: 100%;
        margin: 0;
    }

    .tab-content {
        padding: 1rem 0 0 0;
    }

    /* Konfigurator */
    #config {
        padding: 40px 0;
    }

    .kroki {
        flex-wrap: wrap;
        font-size: .75rem;
    }

    .kroki > * {
        flex-basis: 50%;
        padding: .5rem;
    }

    .kroki > *:nth-child(3) {
        border-left: 1px solid rgba(0, 0, 0, .15);
        border-right: 1px solid rgba(0, 0, 0, .15);
    }

    #config-modal {
        max-width: 90%;
    }

    .config-modal-footer .flex a, .config-modal-footer .flex button {
        width: 120px;
    }

    #segment-konfig .form-line.flex {
        flex-wrap: wrap;
    }

    #segment-konfig .column {
        width: 100%;
        padding: 0;
    }

    /*Call button*/
    .mobile-phone-call-wrapper {
        display: flex;
        position: fixed;
        top: 120px;
        right: 20px;
        z-index: 999999999;
        border-radius: 45px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
    }

    .mobile-phone-button {
        height:50px;
        width:50px;
        border-radius: 40px;
    }

    .mobile-phone-button:after {
        background:none
    }

    .mobile-favourite-button {
        height:50px;
        width:50px;
        border-radius: 40px;
        padding-bottom: 10px;
    }

    /* Labele i ribbony */

    .ribbon-hot-text {
        width: 155px;
        height: 24px;
        padding: 2px;
        top: 20px;
        right: 37px;
    }

    .single-cover .gwarancja-zwrotu {
        top: 55px;
        right: -3px;
        height: 25px;
        padding: 1px;
    }

    .single-cover .gwarancja-zwrotu img {
        max-width: 19px;
        margin-right: .3rem;
        margin-left: .1rem;
    }

    /*Social buttons RWD*/

    .social-button-wrapper {
        margin-top: 0.32rem;
        margin-right: 8px;
        margin-left: 0px;
        width: 24px;
        height: 24px;
        background-color: rgb(23, 111, 183);
        border-radius: 3px;
    }

    .social-button {
        padding: 3px;
        color: white;
        margin-bottom: -3px;
    }

    .social-button-svg {
        padding: 3px;
        margin-bottom: 5px;
    }

    .social-button-svg-wrapper {
        margin-top: 0.32rem;
        margin-right: 8px;
        margin-left: 0px;
        width: 24px;
        height: 24px;
        background-color: rgb(23, 111, 183);
        border-radius: 3px;
    }

    .process-step{
        flex-basis: 33%;
        margin: 3rem 0;
        display: flex;
        justify-content: center;
        background: url(assets/images/icons/arrow_circle_right_grey.svg) 100% 25% no-repeat;
        background-size: 22px;
    }

    .our-strengths, .our-strength-vans, #search-form{
        flex-basis: 100%;
    }

    #search-form{
        padding: 0;
    }

    .our-strengths, .our-strength-vans{
        max-width: 400px;
        margin: 0 auto;
        padding: 0 10px;
    }

    .choose-counter {
        font-size: 2.5rem;
        line-height: 3rem;
        text-align: center;
    }

    .strengths-list{
        padding-left: 0.7rem;
        margin-top: 30px;
    }

    .strengths-list li {
        margin-bottom: 20px;
    }

    #home-intro .mobile-reverse{
        flex-direction: column-reverse;
    }

    .ribbon-hot-text-list-view {
        min-height: 24px;
    }
}

@media all and (max-width: 599px) {

    .grid-view  #favourite-button-offer-page{
        display: inline-block;
        position: absolute;
        top: 89%;
        right: 8rem;
        padding-bottom: 10px;
    }

    .grid-view .offer-img{
        height: 17rem;
    }

    .list-view  #favourite-button-offer-page{
        display: inline-block;
        position: absolute;
        top: 1%;
        right: 0.5rem;
        padding-bottom: 10px;
    }

    .list-view  #favourite-button-offer-page img{
        height: 32px;
        padding-bottom: 10px;
    }

    /* Blog */
    .blogItem, .blogPosts .blogItem {
        flex-basis: 100% !important;
        margin-bottom: 2rem !important;
    }

    .blogItem:last-child {
        margin-bottom: 0;
    }


    /* Lista ofert */
    #offers-list.list-view .offer{
        flex-wrap: wrap;
    }

    #offers-list.grid-view .offer, #offers-list.grid-view .version-popover {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        margin: 0 0 1.5rem;
    }

    #sorting-bar .container {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    #sorting-bar .container > span {
        -ms-flex-preferred-size: 48%;
        flex-basis: 48%;
        margin: 0 1%;
    }

    #view-toggle-wrapper > span {
        margin: 0 1rem;
    }

    #fiter-toggle {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }

    #sorting-toggle {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }

    #view-toggle-wrapper {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .new-used-buttons {
        margin-top: 0rem;
        justify-content: space-between;
    }

    .new-used-button {
        margin-right: 0px;
    }


    /* Fixy */
    .home_banner, .usp-item, .quote-item, .other {
        flex-basis: 100% !important;
    }

    .form-section .form-group, .form-section.three-col .form-group {
        flex-basis: 100%;
    }

    /* Lista ofert */
    #offers-list.list-view .offer .offer-img {
        -ms-flex-preferred-size: 27%;
        flex-basis: 27%;
        padding: 1%;
        -ms-flex-item-align: start;
        align-self: flex-start;
    }

    #offers-list.list-view .offer .offer-img img {
        border-radius: 0;
    }

    #offers-list.list-view .offer .offer-top {
        -ms-flex-preferred-size: 71%;
        flex-basis: 71%;
        padding: 1%;
    }

    #offers-list.list-view .offer .offer-top .offer-points{
        flex-basis: 100%;
    }

    #offers-list.list-view .offer-list-view {
        -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
        padding: 1%;
    }

    #offers-list.list-view .offer .offer-bottom {
        -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
        padding: 1%;
    }

    #offers-list.list-view .offer-list-view {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    #offers-list.list-view .offer-btn, #offers-list.list-view .offer-list-view .ribbon {
        height: auto;
        line-height: 1.25rem;
    }

    #offers-list .offer .offer-top .offer-text, #offers-list.list-view .offer-top .offer-title {
        font-size: .75rem;
    }


    #offers-list.list-view .offer .full-price span {
        font-size: .95rem;
        text-align: right;
    }

    #offers-list.list-view .offer-list-view .ribbon.hot-label, #offers-list.list-view .offer-list-view .ribbon.od-reki-label {
        padding: .2rem;
        height: 20px;
        line-height: 1.2;
        text-align: center;
        width: 100%;
        display: block;
        font-size: 0.7rem;
        margin: 0 0 .1rem;
    }

    .ribbon-hot-text-list-view {
        padding: .2rem;
        height: 18px;
        line-height: 1.2;
        text-align: center;
        width: 100%;
        display: block;
        font-size: 0.55rem;
        margin: 0 0 .1rem;
    }

    #offers-list.list-view .offer-btn {
        padding: 0 .5rem;
    }

    #offers-list.list-view .offer-btn::after {
        width: 8px;
        height: 8px;
        margin-left: 5px;
    }

    .process-step{
        flex-basis: 100%;
        margin: 1rem 0;
        display: flex;
        justify-content: center;
        background: none;
    }

    .process-step:first-child{
        margin-top: 3rem;
    }

    .process-step:last-child{
        margin-bottom: 3rem;
    }

    .step-element{
        display: flex;
        width: 100%;
        align-items: center;
    }

    .process-step img{
        padding-left: 5%;
        flex-basis: 30%;
    }

    .process-step .process-name{
        margin: 0 0 0 10px;
        flex-basis: 70%;
    }


}

@media all and (min-width: 400px) and (max-width: 460px) {

    .grid-view #favourite-button-offer-page {
        display: inline-block;
        position: absolute;
        top: 89.5%;
        right: 8rem;
        margin-top: 3px;
        padding-bottom: 10px;
    }
}

@media all and (max-width: 399px) {

    .grid-view #favourite-button-offer-page {
        display: inline-block;
        position: absolute;
        top: 89.5%;
        right: 8rem;
        margin-top: -4px;
        padding-bottom: 10px;
    }

}

@media all and (max-width: 479px) {

    .seo-text-certs {
        text-align: justify;
        font-size: 0.875rem;
        max-width: 600px;
        float: right;
        display: flex;
        margin-right: 9%;
        margin-left: 2%;
        justify-content: unset !important;
    }

    .seo-text-certs img {
        padding-bottom: 20px;
        max-width: 70px;
        margin-right: 5px;
        margin-left: 12px;
    }

    .reliableCompany img {
        width: 130px;
        margin-top: 6px;
        margin-left: 15px;
        max-width: 160px !important;
    }

    .trustCompany img {
        width: 59px;
        max-width: 150px !important;
    }

    .grid-view  #favourite-button-offer-page{
        display: inline-block;
        position: absolute;
        top: 90%;
        right: 8rem;
        padding-bottom: 10px;
    }

    /* Layout */
    h1 {
        font-size: 1.75rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1.25rem;
    }

    h4 {
        font-size: 1rem;
    }

    h5 {
        font-size: .875rem;
    }

    /* Home */
    #search-form form select:nth-child(2n+1) {
        margin-left: 0;
    }

    #search-form form select:nth-child(2n) {
        margin-right: 0;
    }

    #search-form form select {
        width: 100%;
    }

    #home-intro {
        padding: 30px 0;
    }

    #search-form {
        margin-bottom: 20px;
    }

    #search-form form {
        padding: 1rem;
    }

    .our-strengths, .our-strengths-vans {
        padding: 1.3rem;
    }

    .choose-counter {
        font-size: 2rem;
        line-height: 3rem;
        text-align: center;
    }

    .strengths-list{
        font-size: 1.3rem;
    }

    #home-intro-slider .owl-prev {
        left: 5px;
    }

    #home-intro-slider .owl-next {
        right: 5px;
    }

    #home-intro-slider .slide {
        padding: 0 30px;
        height: 66px;
    }

    #testimonials-slider .owl-prev, #logos-slider .owl-prev, #hot-offers-slider .owl-prev {
        left: -10px;
    }

    #testimonials-slider .owl-next, #logos-slider .owl-next, #hot-offers-slider .owl-next {
        right: -10px
    }

    .owl-prev, .owl-next {
        width: 30px;
        height: 30px;
        margin-top: -15px;
    }

    #newsletter form input {
        -ms-flex-preferred-size: 70%;
        flex-basis: 70%
    }

    #newsletter form button {
        -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
        margin-left: auto;
    }

    #newsletter form button:after {
        display: none;
    }

    /* Footer */
    .footer-column {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }


    /* Page */
    .contact-data.flex {
        display: block;
    }

    /* Oferta */
    .tabs.flex {
        display: block;
    }

    #single-right .form-oplata button[type="button"] {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        margin-right: 0;
    }

    .td-zobacz .btn {
        width: auto;
        padding: 0 .2rem;
    }

    .breadcrumb a, .breadcrumb span {
        display: inline-block;
        font-size: .75rem;
    }

    .breadcrumb a, .breadcrumb span {
        padding: 0 3px;
    }

    table {
        font-size: .75rem;
    }

    table td, table th {
        width: auto !important;
    }

    #form-submit {
        max-width: 90%;
    }

    .fixed-box-right .full-price span {
        font-size: 2.25rem;
    }

    .formLabel {
        font-size: .5rem;
        margin: 0 .25rem 1rem;
        padding: .25rem;
    }

    .single-cover {
        top: 0px;
        right: 0px;
    }

    .single-cover .ribbon-od-reki {
        top: 40px;
    }


    .line .line-middle, .line {
        font-size: .75rem !important;
        line-height: 1.5 !important;
    }

    .line-left {
        flex-basis: 117px;
    }

    #config .back-to-offer {
        float:right;
        position: absolute !important;
        font-size: 0.7rem;
        height: 30px;
        line-height: 20px;
    }

    #config .back-to-offer.request {
        display: none;
    }

    /* Callpage widget */

    .dialog {
        display: flex;
        max-width: 90%;
        width: auto;
        margin: auto;
        margin-top: 20px;
    }

    .bp3-dialog-container {
        display: block;
        margin: 0;
    }

    .bp3-overlay-inline .bp3-overlay-content, .bp3-overlay-scroll-container .bp3-overlay-content {
        position: absolute;
        margin: 0;
    }

    .bp3-tab-list > *:not(:last-child) {
        margin: 0px;
    }

    .bp3-large > .bp3-tab {
        font-size: 11px;
        line-height: 25px;
    }


    .bp3-tab-indicator-wrapper ~ .bp3-tab {
        display: flow;
        padding-left: 0px;
    }

    .bp3-tab-list {
        width: 66%;
    }

    .phone-call {
        font-size: 26px;
    }

    .mobile-phone-call-wrapper {
        display: flex;
        position: fixed;
        top: 120px;
        right: 10px;
        z-index: 99999999;
        border-radius: 45px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
    }

    .mobile-favourite-call-wrapper {
        display: flex;
        position: fixed;
        top: 180px;
        right: 10px;
        z-index: 999999999;
        border-radius: 45px;
        /*box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);*/
    }

    .contact-dialog-close-button {
        position:fixed;
        right:6vw;
        top:25px;
        z-index:99999999;
    }

    /* Labele i ribbony */

    .ribbon-od-reki {
        position: absolute;
        top: 0px;
        left: 0px;
        background: url(assets/images/od-reki-ribbon.svg) center center no-repeat;
        background-size: 100% auto;
        width: 100px;
        height: 100px;
        margin-top: -44px;
        margin-left: -4px;
    }

    .ribbon-hot-text {
        font-size: 11px;
        width: 120px;
        height: 20px;
        padding: 2px;
        top: 10px;
        right: 33px;
    }

    .single-cover .gwarancja-zwrotu {
        font-size: 12px;
        top: 35px;
        right: -3px;
        height: 18px;
        padding: 0px;
        border-radius: 4px;
    }

    .single-cover .gwarancja-zwrotu img {
        max-width: 14px;
        margin-right: .25rem;
        margin-left: .1rem;
    }

    .full-price.grid{
        grid-template-columns: 45% 55%;
        grid-template-rows: 20% 30% 20% 30%;
    }

    .full-price small, .before-form .full-price span.installment-discount {
        grid-column: 2;
    }


    .basic-installment, .min-installment{
        grid-row: 2;
    }

    .full-price small{
        grid-row: 3;
    }

    .installment-discount{
        grid-row: 4;
    }

}

/*Style do drukowania*/

@media print {
    /*Styl drukowania oferty*/
    .tab-content {
        display: block;
    }

    .tab-content strong {
        display: block;
    }

    #equipment-type-switch {
        display: none;
    }

    header {
        display: none;
    }

    #product-menu-wrapper {
        display: none;
    }

    #mobile-menu-trigger-wrapper {
        display: none;
    }

    #content > div.container.flex.flex-wrap {
        display: none;
    }

    #mobile-phone-button {
        display: none;
    }

    #single-intro > div > div.single-heading-1.flex > h1 {
        margin: 0 0 0 0rem;
        line-height: 2rem;
        overflow-wrap: break-word;
        max-width: 375px;
    }

    .breadcrumb {
        display: none;
    }

    #form-submit {
        position: relative;
        float: right;
        margin: auto;
        margin-top: 20px;
        display: block;
    }

    #content #single-right form .fixed-box-left button {
        display: none;
    }

    #single-right form button#send-form {
        display: none;
    }

    .form-fixed-box {
        display: block;
    }

    .fixed-box-left span {
        line-height: 1.4;
        padding-top: 0px;
        color: black;
        font-weight: 900;
        font-size: 1.10rem;
    }

    .fixed-box-right .full-price span {
        font-size: 2.5rem;
        color: #B8970F;
    }

    #hot-offers {
        display: none;
    }

    footer {
        display: none;
    }

    .ribbon-od-reki {
        display: none;
    }

    .flex.gwarancja-zwrotu {
        display: none;
    }

    #favourite-button-head-section {
        display: none;
    }

    .cp-tooltip.cp-button-tooltip.cp-button-section__tooltip {
        display: none;
    }

    #mobile-phone-button {
        height:50px;
        width:50px;
        border-radius: 40px !important;
    }

    #single-right form .form-okres .flex {
        display: none;
    }

    #zmienOkres {
        display: none;
    }

    #zmienOplate {
        display: none;
    }

    #zmienWykup {
        display: none;
    }

    .bp3-popover-wrapper {
        display: none;
    }

    .bp3-popover-target {
        display: none;
    }

    .bp3-popover-open {
        display: none;
    }

    .social-button-wrapper {
        display: none;
    }

    #seller-print-contact {
        display: block;
        margin-left: 52px;
        line-height: 1rem;
    }

    #print-logo {
        display: block;
        float: right;
        margin-top: -113px;
        margin-right: 30px;
    }

    #callpageWrapper {
        display: none;
    }

    .openPrompt{
        display: none;
    }

    .single-models {
        display: none;
    }

    #switch {
        display: none;
    }

    .bp3-popover-wrapper {
        display: none;
    }

    .display-none {
        display: none;
    }

    #cookies {
        display: none;
    }

    .before-form {
        display: none;
    }

    #formOplata {
        display: none;
    }

    #formOkres {
        display: none;
    }

    .form-ubezpieczenie{
        display: none;
    }

    .form-gap {
        display: none;
    }

    .form-options {
        page-break-after: always;
    }

    .ribbon-hot {
        display: none;
    }

    .ribbon-hot-text {
        display: none;
    }

    hr {
        display: none;
    }


    /*Styl drukowania posta na blogu*/

    .mobile-phone-call-wrapper {
        display: none;
    }

    .singleBottom {
        display: none;
    }

    .blogPostsWrapper {
        display: none;
    }

}







