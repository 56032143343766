
.topSearchInput .b3-input { height: 40px; }

.topSearchInput .bp3-input:focus,.topSearchInput  .bp3-input.bp3-active {
    -webkit-box-shadow: 0 0 30px 0 rgba(0,0,0,.2);
    box-shadow: 0 0 30px 0 rgba(0,0,0,.2);
}

.bp3-transition-container {
    z-index: 10000000 !important;
}
